import styled from '@emotion/styled';
import { Common, Default } from '@commons/styles/emotion';

export const Wrapper = styled.div``;

export const DefaultImageInput = styled.div`
  input {
    display: none;
  }
  .file-custom {
    padding: 0 22px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
    height: 42px;
    border-radius: 10px;
    border: ${Default.border};
    background: ${Default.bg};
    .file-icon {
      width: 20px;
      height: 20px;
    }
    p {
      font-weight: 500;
      font-size: 14px;
      color: ${Common.color.point};
    }
  }
`;

export const PdfFileBox = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  .file-box {
    padding: 0 22px;
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    height: 42px;
    border-radius: 10px;
    border: ${Default.border};
    .file-icon {
      width: 20px;
      height: 20px;
    }
    p {
      font-size: 14px;
      font-weight: 500;
    }
    .close-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-left: auto;
      width: 25px;
      height: 25px;
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
  .file-box-click {
    cursor: pointer;
    background: #f4f7fc;
  }
`;

export const InputTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.005em;
  margin-bottom: 10px;
`;
