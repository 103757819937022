import { Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { loginUserState } from '@store/store';

const AdminRoute: any = ({ element, requiredLevel, redirectPath = '/' }: any) => {
  const loginUser = useRecoilValue(loginUserState);

  if (!loginUser || loginUser.level !== requiredLevel) {
    return <Navigate to={redirectPath} />;
  }

  return element;
};

export default AdminRoute;
