import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';

import { ImageInputType } from '@components/newItem/imageInput/type';
import { CustomChangeEvent } from '@commons/types/global.types';
import { storage } from '@commons/libraries/firebase/firebase.config';
import {
  DefaultImageInput,
  InputTitle,
  PreviewImage,
  PreviewImageBox,
  Wrapper,
} from '@components/newItem/imageInput/style';

export default function ImageInput({
  inputTitle,
  fileId,
  isMultiple,
  imageURL,
  setImageURL,
  isUpdate,
}: ImageInputType) {
  const handleChangeFile = async (e: CustomChangeEvent) => {
    const files = e.target.files;
    if (!files) return;

    const promises = Array.from(files).map(async (file) => {
      const storageRef = ref(storage, `images/${file.name}`);
      await uploadBytes(storageRef, file);
      return await getDownloadURL(storageRef);
    });

    const urls = await Promise.all(promises);

    if (isMultiple) {
      setImageURL((prev) => [...prev, ...urls]);
    } else {
      if (imageURL.length > 0) {
        const deletePromises = imageURL
          .map((url) => {
            if (!isUpdate) {
              const imageRef = ref(storage, url);
              return deleteObject(imageRef);
            }
            return undefined;
          })
          .filter((promise) => promise !== undefined);

        await Promise.all(deletePromises);
      }
      setImageURL(urls);
    }
  };

  const handleRemoveImage = (idx: number) => async () => {
    const urlToRemove = imageURL[idx];
    setImageURL((prev) => prev.filter((_, index) => index !== idx));
    if (!isUpdate) {
      const imageRef = ref(storage, urlToRemove);
      await deleteObject(imageRef);
    }
  };

  return (
    <Wrapper>
      {inputTitle ? <InputTitle className="input-title">{inputTitle}</InputTitle> : null}
      {imageURL.length === 0 ? (
        <DefaultImageInput>
          <label className="file-custom" htmlFor={fileId}>
            <span className="file-icon">
              <img src={`${process.env.PUBLIC_URL}/assets/images/icon/file.svg`} alt="file" />
            </span>
            <p>사진을 업로드해주세요</p>
            <span>PNG, JPG 포맷 가능</span>
          </label>
          <input
            type="file"
            id={fileId}
            accept=".png, .jpg, .jpeg"
            onChange={handleChangeFile}
            multiple={isMultiple ? isMultiple : false}
          />
        </DefaultImageInput>
      ) : null}
      {imageURL.length !== 0 ? (
        <PreviewImageBox previewLength={imageURL.length}>
          <div className="inner-scroll">
            <PreviewImage>
              <label className="file-custom-inner-scroll" htmlFor={fileId}>
                <span className="file-icon">
                  <img src={`${process.env.PUBLIC_URL}/assets/images/icon/file.svg`} alt="file" />
                </span>
                <p>사진을 업로드해주세요</p>
                <span>PNG, JPG 포맷 가능</span>
              </label>
              <input
                type="file"
                id={fileId}
                accept=".png, .jpg, .jpeg"
                onChange={handleChangeFile}
                multiple={isMultiple ? isMultiple : false}
              />
            </PreviewImage>
            {imageURL.map((src, idx) => (
              <PreviewImage key={idx}>
                <span className="close-icon" onClick={handleRemoveImage(idx)}>
                  <img src={`${process.env.PUBLIC_URL}/assets/images/icon/close.svg`} alt="close" />
                </span>
                <img src={src} alt={`preview-${idx}`} />
              </PreviewImage>
            ))}
          </div>
        </PreviewImageBox>
      ) : null}
    </Wrapper>
  );
}
