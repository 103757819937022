import { useEffect, useState } from 'react';
import { addDoc, collection, getDocs, query, doc, deleteDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword, deleteUser } from 'firebase/auth';

import { CustomChangeEvent } from '@commons/types/global.types';
import { UserDataType, UserNewType } from '@pages/user/type';
import { db, authService } from '@commons/libraries/firebase/firebase.config';
import { InfoContainer, InfoGroup, Inner, InputGroup, Wrapper } from '@pages/user/style';
import Title from '@components/title';
import TextInput from '@components/newItem/textInput';
import Button from '@components/button';
import Table from '@components/table';

export default function User() {
  const [data, setData] = useState<UserDataType[]>([]);
  const [newData, setNewData] = useState<UserNewType>({
    name: '',
    email: '',
    password: '',
    level: false,
  });
  const tableHead = ['#', '이름', '아이디', '삭제'];
  const tableWidth = ['54px', '15%', '25%', '15%'];

  useEffect(() => {
    void getUserData();
  }, []);

  const handleChangeInput = (e: CustomChangeEvent) => {
    const { id, value } = e.target;
    setNewData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const getUserData = async () => {
    const dataQuery = query(collection(db, 'users'));
    const getData = await getDocs(dataQuery);
    const result: any = getData.docs.map((doc) => {
      const docData = doc.data();
      return {
        id: doc.id,
        uid: docData.uid,
        name: docData.name,
        email: docData.email,
      };
    });
    setData(result || []);
  };

  const handleClickCreateData = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        authService,
        newData.email,
        newData.password
      );
      const user = userCredential.user;

      await addDoc(collection(db, 'users'), {
        ...newData,
        uid: user.uid,
      });

      await getUserData();
      setNewData({
        name: '',
        email: '',
        password: '',
        level: false,
      });
      alert('회원이 추가되었습니다.');
    } catch (error) {
      console.error('ErrorMessage: ', error);
    }
  };

  const handleClickDelete = (id: string) => async () => {
    try {
      const user = authService.currentUser;

      if (user) {
        await deleteUser(user);
        const docRef = doc(db, 'users', id);

        await deleteDoc(docRef);
        await getUserData();
        alert('회원을 삭제했습니다.');
      } else {
        console.error('No user currently signed in.');
      }
    } catch (error) {
      console.error('ErrorMessage:', error);
    }
  };

  return (
    <Wrapper>
      <Title title="회원관리" />
      <Inner>
        <InfoGroup>
          <div className="group-title">회원 추가</div>
          <InfoContainer>
            <InputGroup>
              <TextInput
                inputTitle="회원명"
                inputId="name"
                inputValue={newData.name}
                handleChangeInput={handleChangeInput}
                inputPlaceholder="회원명을 입력해주세요."
              />
            </InputGroup>
            <InputGroup>
              <TextInput
                inputTitle="아이디"
                inputId="email"
                inputValue={newData.email}
                handleChangeInput={handleChangeInput}
                inputPlaceholder="아이디를 입력해주세요."
              />
            </InputGroup>
            <InputGroup>
              <TextInput
                inputType="password"
                inputTitle="비밀번호"
                inputId="password"
                inputValue={newData.password}
                handleChangeInput={handleChangeInput}
                inputPlaceholder="비밀번호를 입력해주세요."
              />
            </InputGroup>
            <Button title="추가하기" radius={20} handleClickBtn={handleClickCreateData} />
          </InfoContainer>
        </InfoGroup>
        <Table
          type="user"
          data={data}
          tableHead={tableHead}
          tableWidth={tableWidth}
          handleClickDelete={handleClickDelete}
        />
      </Inner>
    </Wrapper>
  );
}
