import InputItem from '@components/input';
import { TextInputType } from '@components/newItem/textInput/type';
import { InputTitle, Wrapper } from '@components/newItem/textInput/style';

export default function TextInput({
  inputTitle,
  inputId,
  inputValue,
  handleChangeInput,
  inputPlaceholder,
  inputType,
  isReadOnly,
}: TextInputType) {
  return (
    <Wrapper>
      {inputTitle ? <InputTitle className="input-title">{inputTitle}</InputTitle> : null}
      <InputItem
        isReadOnly={isReadOnly}
        inputType={inputType ? inputType : 'text'}
        inputId={inputId}
        inputHeight={42}
        inputValue={inputValue}
        handleChangeInput={handleChangeInput}
        inputPlaceholder={inputPlaceholder}
        inputBorder
      />
    </Wrapper>
  );
}
