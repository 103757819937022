import styled from "@emotion/styled";

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 21px;
  padding-bottom: 100px;
`;

export const Logo = styled.div`
  width: 128px;
  height: 129px;
  margin-bottom: 10px;
`;

export const Title = styled.h3`
  font-size: 20px;
  font-weight: 700;
`;

export const LoginWrapper = styled.div`
  width: 365px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  div {
    width: 100%;
    height: 50px;

    &.password-input-wrapper {
      cursor: pointer;
      position: relative;
      .password-icon {
        position: absolute;
        top: 50%;
        right: 18px;
        transform: translateY(-50%);
        display: block;
        width: 24px;
        height: 24px;
      }
    }
  }
`;
