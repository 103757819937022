import { Outlet } from 'react-router';

import { Inner, Wrapper } from '@pages/item/style';
import Title from '@components/title';
import useMenuTitle from '@commons/hooks/useMenuTitle';

export default function Item() {
  const { path, name } = useMenuTitle();

  return (
    <Wrapper>
      <Wrapper>
        <Title title={`${path} > ${name}`} />
      </Wrapper>
      <Inner>
        <Outlet />
      </Inner>
    </Wrapper>
  );
}
