import styled from '@emotion/styled';
import { Common, Default } from '@commons/styles/emotion';

export const Wrapper = styled.div`
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  background: #fff;
  &.scroll {
    min-width: 1300px;
    overflow-x: auto;
  }
`;

export const TableHead = styled.div`
  width: 100%;
  display: flex;
  background: #003da6;
  color: #fff;
  font-weight: 500;
  > div {
    height: 44px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const TableBody = styled.div`
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  border-bottom: ${Default.border};

  &:last-of-type {
    border-bottom: none;
  }

  > div {
    width: auto;
    height: 54px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .table-delete-btn {
    span {
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 57px;
      height: 29px;
      border-radius: 50px;
      background: ${Common.color.red};
      color: #fff;
      font-weight: 500;
    }
  }

  .apartment-name {
    height: auto;
    padding: 12px 20px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;

    p {
      font-size: 14px;
      font-weight: 700;

      &:last-of-type {
        font-size: 18px;
        font-weight: 700;

        span {
          color: #b4b4b4;

          &.active {
            color: ${Common.color.point};
          }

          em {
            margin-left: 5px;
            font-style: normal;
          }
        }
      }
    }
  }

  .apartment-info {
    height: auto;
    padding: 12px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    .ai-link {
      display: flex;
      gap: 8px;

      > div {
        display: flex;
        align-items: center;
        gap: 6px;
        padding: 0 16px;
        height: 30px;
        background: #eef2f7;
        border-radius: 50px;
        font-weight: 500;
        font-size: 14px;
        cursor: pointer;

        img {
          width: 18px;
          height: 18px;
        }

        &.ai-link-detail {
          background: ${Common.color.point};
          color: #fff;

          img {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    .ai-meno {
      padding: 0 10px;
      display: flex;
      align-items: center;
      width: 100%;
      height: 30px;
      gap: 8px;
      border-radius: 50px;
      border: ${Default.border};

      span {
        width: 100%;
        display: block;
        padding-bottom: 2px;
        padding-right: 2px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .ai-data {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
      height: 37px;
      padding: 0 25px 2px 25px;
      border-radius: 50px;
      background: ${Default.bg};

      > div {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          width: calc(100% - 100px);
          height: 90%;
          display: flex;
          align-items: center;
          font-weight: 500;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        span {
          height: 90%;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 80px;
          cursor: pointer;
          font-weight: bold;
          color: ${Common.color.point};
        }
      }
    }
  }

  .table-btn-group {
    margin-top: 20px;
    display: flex;
    gap: 12px;
    align-items: center;

    > div {
      display: flex;
      align-items: center;
      gap: 6px;
      padding: 0 16px;
      height: 30px;
      background: #eef2f7;
      border-radius: 50px;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;

      img {
        width: 18px;
        height: 18px;
      }
    }
  }

  .item-info {
    width: 100%;
    height: auto;
    padding: 19px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;

    .item-data-group {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .item-data-item {
        display: flex;
        width: clac(100% - 200px);
        gap: 60px;
        height: 37px;
        padding: 0 25px 2px 25px;
        border-radius: 50px;
        background: ${Default.bg};

        p {
          height: 90%;
          display: flex;
          align-items: center;
          font-weight: 500;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        span {
          height: 90%;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 80px;
          cursor: pointer;
          font-weight: bold;
          color: ${Common.color.point};
        }
      }

      .table-btn-group {
        justify-content: flex-end;
        width: 200px;
        margin: 0;
      }
    }
  }
`;

export const DetailRow = styled.div`
  padding: 20px 0;
  display: flex;
  height: auto;
`;

export const ApartmentName = styled.div`
  height: auto;
  text-align: center;
  font-weight: 600;
`;

export const ApartmentInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: auto;

  div {
    display: flex;

    .title {
      width: 180px;
      font-size: 14px;
      font-weight: 600;
    }

    .cont {
      width: calc(100% - 180px);
    }

    &.data-img {
      .img-wrapper {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        width: 500px;
        height: 470px;
        border: 1px solid ${Common.color.gray};
        p {
          height: auto;
          padding: 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          span {
            height: auto;
            display: flex;
            width: 50%;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: -0.02em;
            &:last-of-type {
              white-space: pre-line;
              justify-content: flex-end;
              text-align: right;
            }
          }
        }
        .img-box {
          height: 100%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
    &.data-img-scroll {
      .cont {
        overflow-x: auto;
        padding-bottom: 28px;
        margin-right: 70px;
        border-bottom: 1px solid ${Common.color.gray};
        &::-webkit-scrollbar {
          height: 4px;
        }
        &::-webkit-scrollbar-track {
          background: transparent;
        }
        &::-webkit-scrollbar-thumb {
          background: #003da6;
        }
        .scroll-wrapper {
          display: flex;
          gap: 12px;
          .img-box {
            border: 1px solid ${Common.color.gray};
            width: 350px;
            height: 350px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
    &.data-text-info {
      display: flex;
      flex-direction: column;
      .data-text {
        height: 57px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: ${Default.border};

        .cont {
          .ai-link {
            display: flex;
            gap: 8px;

            > div {
              display: flex;
              align-items: center;
              gap: 6px;
              padding: 0 16px;
              height: 30px;
              background: #eef2f7;
              border-radius: 50px;
              font-weight: 500;
              font-size: 14px;
              cursor: pointer;

              img {
                width: 18px;
                height: 18px;
              }

              &.ai-link-detail {
                background: ${Common.color.point};
                color: #fff;

                img {
                  width: 20px;
                  height: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
`;
