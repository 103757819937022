import { InputTitle, Wrapper } from '@components/newItem/textarea/style';
import { TextAreaType } from '@components/newItem/textarea/type';

export default function TextArea({
  inputTitle,
  inputId,
  inputValue,
  inputHeight,
  handleChangeTextarea,
  inputPlaceholder,
  isReadOnly,
}: TextAreaType) {
  return (
    <Wrapper inputHeight={inputHeight}>
      {inputTitle ? <InputTitle className="input-title">{inputTitle}</InputTitle> : null}
      <textarea
        readOnly={isReadOnly}
        style={{ height: inputHeight ? `${inputHeight}px}` : `122px` }}
        id={inputId}
        value={inputValue}
        onChange={handleChangeTextarea}
        placeholder={inputPlaceholder}
      ></textarea>
    </Wrapper>
  );
}
