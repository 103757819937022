import styled from "@emotion/styled";
import { Common } from "@commons/styles/emotion";
import { IsMenuLengthType } from "@components/layout/sideMenu/type";

export const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 252px;
  background: ${Common.color.point};
`;

export const Logo = styled.h1`
  display: flex;
  justify-content: center;
  padding: 24px;
  align-items: center;
  img {
    cursor: pointer;
    height: 34px;
    padding-right: 10px;
  }
`;

export const MenuList = styled.div`
  padding: 0 25px;
  margin-bottom: 4px;
  .menu-list-title {
    display: flex;
    align-items: center;
    height: 40px;
    color: #ced4da;
  }
`;
export const Menu = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const DefaultMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  cursor: pointer;
  .menu-title-left {
    display: flex;
    align-items: center;
    gap: 9px;
    font-weight: normal;
    img {
      margin-top: 2px;
      width: 18px;
      height: 18px;
    }
    span.active {
      font-weight: bold;
    }
  }
  .menu-arrow {
    transition: transform 0.3s ease;
    transform: rotate(0deg);
    &.active {
      transform: rotate(90deg);
    }
  }
`;

export const SubMenu = styled.ul`
  transition: height 0.3s ease;
  overflow: hidden;
  height: ${(props: IsMenuLengthType) => `${props.isMenuLength * 40}px`};
  li {
    color: ${Common.color.gray};
    display: flex;
    align-items: center;
    gap: 15px;
    padding-left: 5px;
    height: 40px;
    .sub-menu-title {
      display: flex;
      align-items: center;
      height: 80%;
      cursor: pointer;
    }
    &.active {
      .sub-menu-title {
        font-weight: bold;
        color: #fff;
      }
    }
  }
`;
