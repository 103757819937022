import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { query, collection, getDocs, orderBy } from 'firebase/firestore';

import { SubMenuType } from '@components/layout/sideMenu/type';
import { db } from '@commons/libraries/firebase/firebase.config';
import { menuListState } from '@store/store';

const useMenuUpdate = () => {
  const setMenuList = useSetRecoilState(menuListState);

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const idList = ['village', 'item'];
        const promises = idList.map(async (id) => {
          const dataQuery = query(collection(db, 'menus'), orderBy('title', 'asc'));
          const getData = await getDocs(dataQuery);
          const result: SubMenuType[] = getData.docs.map((doc) => ({
            id: doc.id,
            title: doc.data().title,
          }));
          return { id, result };
        });

        const fetchedData = await Promise.all(promises);

        setMenuList((prevMenuList) =>
          prevMenuList.map((item) => {
            const matchingData = fetchedData.find((data) => data.id === item.id);
            if (matchingData) {
              return { ...item, subMenu: matchingData.result };
            } else {
              return item;
            }
          })
        );
      } catch (error) {
        console.error('ErrorMessage: ', error);
      }
    };

    void fetchMenuData();
  }, [setMenuList]);
};

export default useMenuUpdate;
