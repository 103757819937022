import styled from '@emotion/styled';
import { Default } from '@commons/styles/emotion';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
`;

export const Page = styled.div`
  height: 100%;
  width: calc(100vw - 252px);
  margin-left: 252px;
  background: ${Default.bg};
  > section {
    height: 100%;
  }
`;
