import styled from '@emotion/styled';
import { Common, Default } from '@commons/styles/emotion';
import { IPropsPreviewLengthStyleType } from '@components/newItem/imageInput/type';

export const Wrapper = styled.div``;

export const DefaultImageInput = styled.div`
  input {
    display: none;
  }
  .file-custom {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    width: 100%;
    height: 156px;
    border-radius: 10px;
    border: ${Default.border};
    background: ${Default.bg};
    .file-icon {
      width: 24px;
      height: 24px;
      margin-bottom: 2px;
    }
    p {
      font-weight: bold;
      font-size: 14px;
      color: ${Common.color.point};
    }
    span {
      font-size: 12px;
      color: ${Common.color.darkGray};
    }
  }
`;

export const PreviewImageBox = styled.div`
  width: 100%;
  overflow-x: scroll;
  border-bottom: ${(props: IPropsPreviewLengthStyleType) =>
    props.previewLength === 1 ? `none` : `1px solid ${Common.color.gray}`};

  .inner-scroll {
    width: ${(props: IPropsPreviewLengthStyleType) =>
      props.previewLength ? `${props.previewLength * 356 + 356}px` : '0px'};

    display: flex;
    gap: 12px;
  }
  .inner-scroll-img {
    width: ${(props: IPropsPreviewLengthStyleType) =>
      props.previewLength ? `${props.previewLength * 356}px` : '0px'};
  }

  &::-webkit-scrollbar {
    height: 4px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #003da6;
  }
`;
export const PreviewImage = styled.div`
  margin: 14px 0;
  position: relative;
  width: 350px;
  height: 350px;
  overflow: hidden;
  border: 1px solid ${Common.color.gray};

  input {
    display: none;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .close-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${Common.color.gray};
    top: 0;
    right: 0;
    width: 38px;
    height: 38px;
    z-index: 10;
    position: absolute;
    cursor: pointer;
    img {
      width: 28px;
      height: 28px;
    }
  }
  .file-custom-inner-scroll {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    width: 100%;
    height: 100%;
    background: ${Default.bg};
    .file-icon {
      width: 24px;
      height: 24px;
      margin-bottom: 2px;
    }
    p {
      font-weight: bold;
      font-size: 14px;
      color: ${Common.color.point};
    }
    span {
      font-size: 12px;
      color: ${Common.color.darkGray};
    }
  }
`;

export const InputTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.005em;
  margin-bottom: 10px;
`;
