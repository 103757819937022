import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { collection, getDocs, query, doc, deleteDoc, orderBy } from 'firebase/firestore';

import { VillageNewType } from '@pages/village/new/type';
import { db } from '@commons/libraries/firebase/firebase.config';
import { Wrapper } from '@pages/village/list/style';
import Table from '@components/table';

export default function VillageList() {
  const { typeId } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState<VillageNewType[]>([]);
  const tableHead = ['아파트명', '단지정보', ''];
  const tableWidth = ['20%', '60%', '20%'];

  useEffect(() => {
    const fetchData = async () => {
      if (typeId) {
        const result: any = await getVillageData(typeId);
        setData(result);
      }
    };

    void fetchData();
  }, [typeId]);

  const getVillageData = async (typeId: string) => {
    const dataQuery = query(collection(db, String(typeId)), orderBy('apartmentName', 'asc'));
    const getData = await getDocs(dataQuery);
    const result = getData.docs.map((doc) => {
      const docData = doc.data();
      return {
        id: doc.id,
        apartmentName: docData.apartmentName,
        naver: docData.naver,
        daum: docData.daum,
        hogangnono: docData.hogangnono,
        kkachi: docData.kkachi,
        gyokcharo: docData.gyokcharo,
        memo: docData.memo,
        itemList: docData.itemList,
      };
    });
    return result || [];
  };

  const handleClickUpdate = (id: string) => async () => {
    navigate(`/village/update/${typeId}/${id}`);
  };

  const handleClickDelete = (id: string) => async () => {
    try {
      const docRef = doc(db, String(typeId), id);
      await deleteDoc(docRef);
      const updatedData: any = await getVillageData(String(typeId));
      setData(updatedData);
      alert('단지정보를 삭제했습니다.');
      if (data.length === 1) {
        try {
          const docRef = doc(db, 'menus', String(typeId));
          await deleteDoc(docRef);
          navigate(`/`);
        } catch (error) {
          console.error('ErrorMessage:', error);
        }
      }
    } catch (error) {
      console.error('ErrorMessage:', error);
    }
  };

  return (
    <Wrapper>
      <Table
        type="village"
        data={data}
        tableHead={tableHead}
        tableWidth={tableWidth}
        handleClickDelete={handleClickDelete}
        handleClickUpdate={handleClickUpdate}
        router={String(typeId)}
      />
    </Wrapper>
  );
}
