import { Wrapper } from '@pages/village/detail/style';
import Table from '@components/table';
import { useParams } from 'react-router-dom';
import { VillageNewType } from '@pages/village/new/type';
import { useEffect, useState } from 'react';
import { collection, getDocs, query } from 'firebase/firestore';
import { db } from '@commons/libraries/firebase/firebase.config';

export default function VillageDetail() {
  const { typeId, id } = useParams();
  const [data, setData] = useState<VillageNewType>();
  const tableHead = ['아파트명', '상세단지정보'];
  const tableWidth = ['200px', 'calc(100% - 200px)'];

  useEffect(() => {
    const fetchData = async () => {
      if (typeId) {
        const result: any = await getVillageData(typeId);
        setData(result);
      }
    };

    void fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeId]);

  const getVillageData = async (typeId: string) => {
    const dataQuery = query(collection(db, String(typeId)));
    const getData = await getDocs(dataQuery);
    const result: any = getData.docs
      .filter((doc) => doc.id === id)
      .map((doc) => {
        const docData = doc.data();
        return {
          ...docData,
          id: doc.id,
        } as any;
      });
    return result[0] || {};
  };

  return (
    <Wrapper>
      <Table
        type="villageDetail"
        data={data}
        tableHead={tableHead}
        tableWidth={tableWidth}
        router={String(typeId)}
      />
    </Wrapper>
  );
}
