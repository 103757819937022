import styled from '@emotion/styled';
import { Common } from '@commons/styles/emotion';
import { IPropsButtonStyleType } from '@components/button/type';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props: IPropsButtonStyleType) => (props.width ? `${props.width}px` : '100%')};
  height: ${(props: IPropsButtonStyleType) => (props.height ? `${props.height}px` : '50px')};
  border-radius: ${(props: IPropsButtonStyleType) => (props.radius ? `${props.radius}px` : '10px')};
  background: ${Common.color.point};
  color: #fff;
  font-weight: 500;
  cursor: pointer;
`;
