import { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useRecoilState, useRecoilValue } from 'recoil';

import { isSideMenuState, loginUserState, menuListState } from '@store/store';
import { MenuListType, SubMenuType } from '@components/layout/sideMenu/type';
import {
  DefaultMenu,
  Logo,
  Menu,
  MenuList,
  Wrapper,
  SubMenu,
} from '@components/layout/sideMenu/style';
import useMenuUpdate from '@commons/hooks/useMenuUpdate';

export default function SideMenu() {
  const router = useLocation();
  const navigate = useNavigate();
  const menuList = useRecoilValue(menuListState);
  const [isMenuOpen, setIsMenuOpen] = useRecoilState(isSideMenuState);
  const [isCurrentMenu, setIsCurrentMenu] = useState('/');
  const loginUser = useRecoilValue(loginUserState);

  const handleOpenMenu = (idx: number) => () => {
    setIsMenuOpen((prevState) => {
      const updatedMenuOpen = [...prevState];
      updatedMenuOpen[idx] = !updatedMenuOpen[idx];
      return updatedMenuOpen;
    });
  };

  const handleMoveToMenu =
    (idx: number, link: string | undefined, id: string | undefined) => () => {
      if (link) {
        return navigate(`${link}`);
      }

      if (idx === 0) {
        void navigate(`/village/list/${id}`);
      } else {
        void navigate(`/item/list/${id}`);
      }
    };

  useEffect(() => {
    setIsCurrentMenu(router.pathname);
  }, [router]);

  useEffect(() => {
    localStorage.setItem('isSideMenu', JSON.stringify(isMenuOpen));
  }, [isMenuOpen]);

  useMenuUpdate();

  return (
    <Wrapper>
      <Logo>
        <img
          onClick={() => {
            navigate('/');
          }}
          src={`${process.env.PUBLIC_URL}/assets/images/logo-w.svg`}
          alt=""
        />
      </Logo>
      <MenuList>
        <span className="menu-list-title">MENU</span>
        {menuList.map((mainMenu: MenuListType, idx: number) => (
          <Menu key={`menu-${idx}`}>
            <>
              <DefaultMenu
                id={mainMenu.link}
                className="default-menu"
                onClick={handleOpenMenu(idx)}
              >
                <div className="menu-title-left">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/icon/${mainMenu.icon}.svg`}
                    alt={mainMenu.icon}
                  />
                  <span className={isCurrentMenu === mainMenu.link ? 'active' : ''}>
                    {mainMenu.title}
                  </span>
                </div>
                <img
                  className={
                    mainMenu.subMenu.length !== 0 && isMenuOpen[idx]
                      ? 'menu-arrow active'
                      : 'menu-arrow'
                  }
                  src={`${process.env.PUBLIC_URL}/assets/images/icon/arrow.svg`}
                  alt="arrow"
                />
              </DefaultMenu>
              <SubMenu isMenuLength={isMenuOpen[idx] ? mainMenu.subMenu.length : 0}>
                {mainMenu.subMenu.map((subMenu: SubMenuType, i: number) => (
                  <Fragment key={`subMenu-${i}`}>
                    {!loginUser?.level &&
                    mainMenu.id === 'new' &&
                    subMenu.id !== 'item-new' ? null : (
                      <li
                        key={subMenu.id}
                        className={isCurrentMenu === '/neighborhood' && i === 0 ? 'active' : ''}
                      >
                        <span>-</span>
                        <p
                          className="sub-menu-title"
                          onClick={handleMoveToMenu(idx, subMenu.link, subMenu.id)}
                        >
                          {subMenu.title}
                        </p>
                      </li>
                    )}
                  </Fragment>
                ))}
              </SubMenu>
            </>
          </Menu>
        ))}
      </MenuList>
    </Wrapper>
  );
}
