import styled from '@emotion/styled';
import { Common, Default } from '@commons/styles/emotion';
import { IPropsSelectBoxStyleType, IPropsSelectListType } from '@components/newItem/selectBox/type';

export const Wrapper = styled.div`
  position: relative;
`;

export const DefaultSelect = styled.div`
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 42px;
  border-radius: 10px;
  border: ${Default.border};
  padding: 0 28px;
  display: flex;
  align-items: center;
  margin-bottom: ${(props: IPropsSelectBoxStyleType) => (props.mb ? `${props.mb}px` : '0px')};

  p {
    color: ${(props: IPropsSelectBoxStyleType) =>
      props.isSelect ? `${Default.color}` : `${Common.color.lightGray}`};
  }

  .arrow-icon {
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
`;

export const SelectList = styled.ul`
  position: absolute;
  z-index: 999;
  top: 80px;
  box-sizing: border-box;
  transition: height 0.4s ease;
  height: ${(props: IPropsSelectListType) =>
    props.isListOpen ? `${props.listLength * 36}px` : '0px'};

  width: 100%;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  li {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 36px;
    padding: 0 28px;
    border-left: ${Default.border};
    border-right: ${Default.border};
    border-bottom: ${Default.border};
    background: #fff;

    &:first-of-type {
      border-radius: 10px 10px 0 0;
      border-top: ${Default.border};
    }
    &:last-of-type {
      border-radius: 0 0 10px 10px;
      border-bottom: ${Default.border};
    }
    &:hover {
      background: ${Default.bg};
    }
  }
`;

export const InputTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.005em;
  margin-bottom: 10px;
`;
