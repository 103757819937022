import { Route, Routes } from 'react-router-dom';

import Login from '@pages/login';
import Layout from '@commons/layout';
import Dashboard from '@pages/dashboard';
import User from '@pages/user';
import Village from '@pages/village';
import VillageNew from '@pages/village/new';
import VillageList from '@pages/village/list';
import VillageDetail from '@pages/village/detail';
import Item from '@pages/item';
import ItemList from '@pages/item/list';
import ItemNew from '@pages/item/new';
import ItemDetail from '@pages/item/detail';
import PrivateRoute from '@commons/privateRoute';
import AdminRoute from '@commons/adminRoute';

export default function App() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<PrivateRoute element={<Layout />} />}>
        <Route index element={<Dashboard />} />
        <Route path="village" element={<Village />}>
          <Route path="list/:typeId" element={<VillageList />} />
          <Route path="detail/:typeId/:id" element={<VillageDetail />} />
          <Route
            path="update/:typeId/:itemId"
            element={<AdminRoute element={<VillageNew />} requiredLevel={true} />}
          />
          <Route
            path="new"
            element={<AdminRoute element={<VillageNew />} requiredLevel={true} />}
          />
        </Route>
        <Route path="item" element={<Item />}>
          <Route path="list/:typeId" element={<ItemList />} />
          <Route path="detail/:typeId/:itemId/:id" element={<ItemDetail />} />
          <Route path="new" element={<ItemNew />} />
          <Route path="update/:typeId/:itemId/:id" element={<ItemNew />} />
        </Route>
        <Route path="user" element={<AdminRoute element={<User />} requiredLevel={true} />} />
      </Route>
    </Routes>
  );
}
