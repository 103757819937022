import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useSetRecoilState } from 'recoil';
import { collection, getDocs, where, query } from 'firebase/firestore';

import { authService, db } from '@commons/libraries/firebase/firebase.config';
import { loginUserState } from '@store/store';

const PrivateRoute: any = ({ element, ...rest }: any) => {
  const setLoginUser = useSetRecoilState(loginUserState);
  const [user, loading, error] = useAuthState(authService);

  useEffect(() => {
    if (user) {
      const fetchMenuData = async () => {
        try {
          const dataQuery = query(collection(db, 'users'), where('uid', '==', user.uid));
          const getData = await getDocs(dataQuery);
          const result = getData.docs.map((doc) => {
            const data = doc.data();
            return {
              email: data.email,
              level: data.level,
              name: data.name,
              uid: data.uid,
            };
          });
          setLoginUser(result[0]);
        } catch (error) {
          console.error('ErrorMessage: ', error);
        }
      };

      void fetchMenuData();
    }
  }, [user, setLoginUser]);

  if (loading) {
    return <div></div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return user ? element : <Navigate to="/login" />;
};

export default PrivateRoute;
