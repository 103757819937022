import { Wrapper } from "@components/button/style";
import { IButtonType } from "@components/button/type";

export default function Button({
  title,
  width,
  height,
  radius,
  handleClickBtn,
}: IButtonType) {
  return (
    <Wrapper
      width={width}
      height={height}
      radius={radius}
      onClick={handleClickBtn}
    >
      {title}
    </Wrapper>
  );
}
