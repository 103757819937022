export const Default = {
  color: '#2B343A',
  bg: '#F4F7FC',
  font: "'Noto Sans KR', sans-serif;",
  border: '0.5px solid #CDD5D9',
};

export const Common = {
  color: {
    point: '#003DA6',
    darkPoint: '#001141',
    gray: '#CED4DA',
    lightGray: '#CDD5D9',
    darkGray: '#ADB6C6',
    red: '#C80D0D',
  },
};
