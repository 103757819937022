import { atom } from 'recoil';
import { MenuListType } from '@components/layout/sideMenu/type';

export const loginUserState = atom<any>({
  key: 'loginUserState',
  default: null,
});

export const menuListState = atom<MenuListType[]>({
  key: 'menuListState',
  default: [
    {
      id: 'village',
      title: '단지정보',
      link: '/village',
      icon: 'village',
      isOpen: false,
      subMenu: [],
    },
    {
      id: 'item',
      title: '매물정보',
      link: '/item',
      icon: 'village',
      isOpen: false,
      subMenu: [],
    },
    {
      id: 'new',
      title: '정보 입력',
      link: '',
      icon: 'user',
      isOpen: false,
      subMenu: [
        {
          id: 'village-new',
          title: '단지등록',
          link: '/village/new',
        },
        {
          id: 'item-new',
          title: '매물등록',
          link: '/item/new',
        },
        {
          id: '',
          title: '회원등록',
          link: '/user',
        },
      ],
    },
  ],
});

const localStorageValue = localStorage.getItem('isSideMenu');
const initialSideMenuState: boolean[] = localStorageValue
  ? JSON.parse(localStorageValue)
  : [false, false, false];

export const isSideMenuState = atom<boolean[]>({
  key: 'isSideMenuState',
  default: initialSideMenuState,
});
