import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useRecoilValue } from 'recoil';
import { signOut } from 'firebase/auth';

import { CustomChangeEvent } from '@commons/types/global.types';
import { loginUserState } from '@store/store';
import { Logout, ProfileBox, Wrapper } from '@components/layout/header/style';
import { authService } from '@commons/libraries/firebase/firebase.config';
import InputItem from '@components/input';

export default function Header() {
  const navigate = useNavigate();
  const loginUser = useRecoilValue(loginUserState);
  const [searchValue, setSearchValue] = useState<string>('');

  const handleChangeInput = (e: CustomChangeEvent) => {
    setSearchValue(e.target.value);
  };

  const handleLogout = async () => {
    await signOut(authService);
    alert('로그아웃 되었습니다.');
    void navigate('/login');
  };

  return (
    <Wrapper>
      <div className="search-input-wrapper">
        <InputItem
          inputType="text"
          inputId="search"
          inputWidth={380}
          inputHeight={38}
          inputValue={searchValue}
          handleChangeInput={handleChangeInput}
          inputPlaceholder="Search..."
          inputBg
        />
        <span className="search-icon">
          <img src={`${process.env.PUBLIC_URL}/assets/images/icon/magnify.svg`} alt="magnify" />
        </span>
      </div>

      <ProfileBox>
        <Logout onClick={handleLogout}>
          <span className="logout-icon">
            <img src={`${process.env.PUBLIC_URL}/assets/images/icon/logout.svg`} alt="logout" />
          </span>
        </Logout>
        <div className="login-user">
          <p>{loginUser?.name}님</p>
          <span>{loginUser?.level ? '관리자' : '일반회원'}</span>
        </div>
      </ProfileBox>
    </Wrapper>
  );
}
