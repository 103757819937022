import styled from '@emotion/styled';
import { Common } from '@commons/styles/emotion';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  height: 70px;
  padding: 0 22px;

  .search-input-wrapper {
    position: relative;
    .search-icon {
      position: absolute;
      top: 50%;
      left: 13px;
      transform: translateY(-50%);
      display: block;
      width: 20px;
      height: 20px;
    }
    input {
      padding: 16px 40px;
    }
  }
`;

export const ProfileBox = styled.div`
  height: 100%;
  display: flex;
  padding: 0 26px 0 16px;
  background: #f4f7fc;
  align-items: center;
  gap: 10px;

  .login-user {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    p {
      color: #003da6;
      font-size: 13px;
      font-weight: 800;
    }
    span {
      color: ${Common.color.darkGray};
      font-size: 12px;
      font-weight: 400;
    }
  }
`;

export const Logout = styled.div`
  padding: 10px;
  cursor: pointer;
`;
