import { ReactElement } from 'react';
import { Outlet } from 'react-router';

import { Page, Wrapper } from '@commons/layout/style';
import SideMenu from '@components/layout/sideMenu';
import Header from '@components/layout/header';

export default function Layout({ children }: IPropsLayout) {
  return (
    <Wrapper>
      <SideMenu />
      <Page>
        <Header />
        <section>{children || <Outlet />}</section>
      </Page>
    </Wrapper>
  );
}

interface IPropsLayout {
  children?: ReactElement;
}
