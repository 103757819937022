import styled from '@emotion/styled';
import { Common, Default } from '@commons/styles/emotion';
import { IPropsInputStyleType } from '@components/input/type';

export const Wrapper = styled.div`
  input {
    font-size: 14px;
    padding: 0 28px;
    border-radius: 10px;
    width: ${(props: IPropsInputStyleType) =>
      props.inputWidth ? `${props.inputWidth}px` : '100%'};
    height: ${(props: IPropsInputStyleType) =>
      props.inputHeight ? `${props.inputHeight}px` : '50px'};
    background: ${(props: IPropsInputStyleType) => (props.inputBg ? `#F4F7FC` : '#ffffff')};
    border: ${(props: IPropsInputStyleType) =>
      props.inputBorder ? `${Default.border}` : 'transparent'};
    &::placeholder {
      color: ${Common.color.lightGray};
    }
  }
`;
