import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { MenuListType, SubMenuType } from '@components/layout/sideMenu/type';
import { menuListState } from '@store/store';

const useMenuTitle = () => {
  const location = useLocation();
  const [path, setPath] = useState('');
  const [name, setName] = useState('');
  const menuList = useRecoilValue(menuListState);

  useEffect(() => {
    const router = location.pathname.split('/').slice(1);
    const menu = menuList.find((item: MenuListType) => item.id === router[0]);

    if (router[0] === 'village') {
      setPath('단지정보');
      if (router[1] === 'new') {
        setName('단지등록');
      }
    } else if (router[0] === 'item') {
      setPath('매물정보');
      if (router[1] === 'new') {
        setName('매물등록');
      }
    }

    if (menu) {
      const subMenu = menu.subMenu.find((sub: SubMenuType) => sub.id === router[2]);

      if (subMenu) {
        setName(subMenu.title);
      }
    }
  }, [location, menuList, path]);

  return { path, name };
};

export default useMenuTitle;
