import styled from "@emotion/styled";

export const Wrapper = styled.div``;

export const InputTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.005em;
  margin-bottom: 10px;
`;
