import { useEffect, useState } from 'react';
import { SelectBoxType } from '@components/newItem/selectBox/type';
import {
  DefaultSelect,
  InputTitle,
  SelectList,
  Wrapper,
} from '@components/newItem/selectBox/style';
import { CustomMouseEvent } from '@commons/types/global.types';
import { SubMenuType } from '@components/layout/sideMenu/type';

export default function SelectBox({
  inputId,
  list,
  inputTitle,
  mb,
  handleSelectValue,
  selectBoxPlaceholder,
  isValue,
}: SelectBoxType) {
  const [isSelect, setIsSelect] = useState(false);
  const [isListOpen, setIsListOpen] = useState(false);
  const [selectValue, setSelectValue] = useState(selectBoxPlaceholder);

  useEffect(() => {
    if (isValue) {
      setIsSelect(true);
      setSelectValue(isValue);
    }
  }, [isValue]);

  const handleClickListOpen = () => {
    setIsListOpen((prev) => !prev);
  };

  const handleClickSelect = (e: CustomMouseEvent) => {
    const subMenu = list.find((sub: SubMenuType) => sub.id === e.currentTarget.id);

    setIsListOpen(false);
    setIsSelect(true);
    setSelectValue(subMenu.title);
    handleSelectValue(subMenu, inputId);
  };

  return (
    <Wrapper id={inputId}>
      {inputTitle ? <InputTitle className="input-title">{inputTitle}</InputTitle> : null}
      <DefaultSelect mb={mb} isSelect={isSelect} onClick={handleClickListOpen}>
        <p>{selectValue}</p>
        <span className="arrow-icon">
          <img src={`${process.env.PUBLIC_URL}/assets/images/icon/arrow-bk.svg`} alt="arrow" />
        </span>
      </DefaultSelect>
      <SelectList isListOpen={isListOpen} listLength={list.length}>
        {list.map((value: any) => (
          <li key={value.id} id={value.id} onClick={handleClickSelect}>
            {value.title}
          </li>
        ))}
      </SelectList>
    </Wrapper>
  );
}
