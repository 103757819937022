import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useRecoilValue } from 'recoil';
import { collection, addDoc, query, getDocs, doc, updateDoc } from 'firebase/firestore';

import { VillageNewType } from '@pages/village/new/type';
import { CustomChangeEvent, CustomTextareaChangeEvent } from '@commons/types/global.types';
import { db } from '@commons/libraries/firebase/firebase.config';
import { menuListState } from '@store/store';
import { InfoContainer, InfoGroup, InputGroup, Wrapper } from '@pages/village/new/style';
import TextInput from '@components/newItem/textInput';
import TextArea from '@components/newItem/textarea';
import SelectBox from '@components/newItem/selectBox';
import ImageInput from '@components/newItem/imageInput';
import Button from '@components/button';

export default function VillageNew() {
  const { typeId, itemId } = useParams();
  const navigate = useNavigate();
  const menuList = useRecoilValue(menuListState);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [selectList, setSelectList] = useState<any>([]);
  const [imageURL1, setImageURL1] = useState<string[]>([]);
  const [imageURL2, setImageURL2] = useState<string[]>([]);
  const [newData, setNewData] = useState<VillageNewType>({
    typeId: '',
    typeName: '',
    apartmentName: '',
    apartmentAddress: '',
    apartmentPhone: '',
    allNumber: '',
    lowFloor: '',
    highFloor: '',
    parkingLot: '',
    corridorType: '',
    maintenanceCost: '',
    memo: '',
    naver: '',
    daum: '',
    hogangnono: '',
    kkachi: '',
    gyokcharo: '',
    representativeImageUrl: [],
    typeImageUrls: [],
    itemList: [],
  });
  const [updateData, setUpdateData] = useState<any>({
    id: '',
    typeId: '',
    typeName: '',
    apartmentName: '',
    apartmentAddress: '',
    apartmentPhone: '',
    allNumber: '',
    lowFloor: '',
    highFloor: '',
    parkingLot: '',
    corridorType: '',
    maintenanceCost: '',
    memo: '',
    naver: '',
    daum: '',
    hogangnono: '',
    kkachi: '',
    gyokcharo: '',
    representativeImageUrl: [],
    typeImageUrls: [],
    itemList: [],
  });

  useEffect(() => {
    if (typeId && itemId) {
      setIsUpdate(true);
      void getUpdateData(String(typeId), String(itemId));
    }
  }, [typeId, itemId]);

  const getUpdateData = async (typeId: string, itemId: string) => {
    try {
      const dataQuery = query(collection(db, typeId));
      const getData = await getDocs(dataQuery);

      const result: any = getData.docs
        .filter((doc) => doc.id === itemId)
        .map((doc) => {
          const docData = doc.data();
          return {
            ...docData,
            id: doc.id,
          } as any;
        });

      if (itemId && result.length > 0) {
        setUpdateData(result[0]);
        if (result[0].representativeImageUrl) {
          setImageURL1(result[0].representativeImageUrl);
          setImageURL2(result[0].typeImageUrls);
        }
      }
    } catch (error) {
      console.error('ErrorMessage:', error);
    }
  };

  useEffect(() => {
    const villageMenu = menuList.find((menu) => menu.id === 'village');
    if (villageMenu) {
      setSelectList([
        ...villageMenu.subMenu,
        {
          id: 'new',
          title: '새로 추가',
        },
      ]);
    }
  }, [menuList]);

  useEffect(() => {
    if (isUpdate) {
      setUpdateData((prevData: any) => ({
        ...prevData,
        representativeImageUrl: imageURL1,
        typeImageUrls: imageURL2,
      }));
    } else {
      setNewData((prevData) => ({
        ...prevData,
        representativeImageUrl: imageURL1,
        typeImageUrls: imageURL2,
      }));
    }
  }, [imageURL1, imageURL2, isUpdate]);

  const handleChangeInput = (e: CustomChangeEvent | CustomTextareaChangeEvent) => {
    if (itemId) {
      const { id, value } = e.target;
      setUpdateData((prevState: any) => ({
        ...prevState,
        [id]: value,
      }));
    } else {
      const { id, value } = e.target;
      setNewData((prevState) => ({
        ...prevState,
        [id]: value,
      }));
    }
  };

  const handleSelectValue = (selectValue: any) => {
    if (isUpdate) {
      setUpdateData((prevState: any) => ({
        ...prevState,
        typeId: selectValue.id,
        typeName: selectValue.id === 'new' ? '' : selectValue.title,
      }));
    } else {
      setNewData((prevState) => ({
        ...prevState,
        typeId: selectValue.id,
        typeName: selectValue.id === 'new' ? '' : selectValue.title,
      }));
    }
  };

  const handleClickCreateData = async () => {
    let newMenuId = null;

    if (newData.typeId === 'new') {
      try {
        const docRef = await addDoc(collection(db, 'menus'), {
          title: newData.typeName,
        });
        newMenuId = docRef.id;
      } catch (error) {
        console.error('ErrorMessage: ', error);
        return;
      }
    }

    try {
      const updatedData = {
        ...newData,
        typeId: newMenuId ? newMenuId : newData.typeId,
      };

      await addDoc(collection(db, `${newMenuId ? newMenuId : newData.typeId}`), updatedData);
      alert('단지가 등록되었습니다.');
      void navigate('/');
    } catch (error) {
      console.error('ErrorMessage: ', error);
    }
  };

  const handleClickUpdateData = async () => {
    try {
      const userRef = doc(db, String(typeId), String(itemId));
      await updateDoc(userRef, {
        ...updateData,
      });
      alert('단지가 수정되었습니다.');
      void navigate('/');
    } catch (error) {
      console.error('ErrorMessage:', error);
    }
  };

  return (
    <Wrapper>
      <InfoGroup>
        <div className="group-title">기본 정보</div>
        <InfoContainer>
          <InputGroup>
            <SelectBox
              isValue={isUpdate ? updateData.typeName : newData.typeName}
              list={selectList}
              inputTitle="동 선택(기존동 또는 새로 추가)"
              selectBoxPlaceholder="동을 선택해주세요."
              mb={8}
              handleSelectValue={handleSelectValue}
            />
            <TextInput
              inputId="typeName"
              inputValue={isUpdate ? updateData.typeName : newData.typeName}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="동 이름을 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextInput
              inputTitle="아파트 이름"
              inputId="apartmentName"
              inputValue={isUpdate ? updateData.apartmentName : newData.apartmentName}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="아파트 이름을 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextInput
              inputTitle="아파트 주소"
              inputId="apartmentAddress"
              inputValue={isUpdate ? updateData.apartmentAddress : newData.apartmentAddress}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="아파트 주소를 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextInput
              inputTitle="관리사무소 연락처"
              inputId="apartmentPhone"
              inputValue={isUpdate ? updateData.apartmentPhone : newData.apartmentPhone}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="관리사무소 연락처를 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <ImageInput
              isUpdate={isUpdate}
              fileId="representativeImageUrl"
              inputTitle="대표 단지 사진 등록(1개)"
              imageURL={imageURL1}
              setImageURL={setImageURL1}
            />
          </InputGroup>
          <InputGroup>
            <ImageInput
              isUpdate={isUpdate}
              fileId="typeImageUrls"
              isMultiple
              inputTitle="타입별 슬라이드 사진 등록"
              imageURL={imageURL2}
              setImageURL={setImageURL2}
            />
          </InputGroup>
        </InfoContainer>
      </InfoGroup>
      <InfoGroup>
        <div className="group-title">사용 승인</div>
        <InfoContainer>
          <InputGroup>
            <TextInput
              inputTitle="총 세대수"
              inputId="allNumber"
              inputValue={isUpdate ? updateData.allNumber : newData.allNumber}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="총 세대수를 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextInput
              inputTitle="최저층"
              inputId="lowFloor"
              inputValue={isUpdate ? updateData.lowFloor : newData.lowFloor}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="최저층을 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextInput
              inputTitle="최고층"
              inputId="highFloor"
              inputValue={isUpdate ? updateData.highFloor : newData.highFloor}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="최고층을 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextInput
              inputTitle="주차대수"
              inputId="parkingLot"
              inputValue={isUpdate ? updateData.parkingLot : newData.parkingLot}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="주차대수를 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextInput
              inputTitle="복도유형"
              inputId="corridorType"
              inputValue={isUpdate ? updateData.corridorType : newData.corridorType}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="복도유형을 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextInput
              inputTitle="선수관리비"
              inputId="maintenanceCost"
              inputValue={isUpdate ? updateData.maintenanceCost : newData.maintenanceCost}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="선수관리비를 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextArea
              inputTitle="메모"
              inputId="memo"
              inputValue={isUpdate ? updateData.memo : newData.memo}
              handleChangeTextarea={handleChangeInput}
              inputPlaceholder="메모를 입력해주세요."
            />
          </InputGroup>
        </InfoContainer>
      </InfoGroup>
      <InfoGroup>
        <div className="group-title">링크 입력</div>
        <InfoContainer>
          <InputGroup>
            <TextInput
              inputTitle="네이버 지도"
              inputId="naver"
              inputValue={isUpdate ? updateData.naver : newData.naver}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="네이버 지도 링크를 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextInput
              inputTitle="다음 지도"
              inputId="daum"
              inputValue={isUpdate ? updateData.daum : newData.daum}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="다음 지도 링크를 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextInput
              inputTitle="호갱노노"
              inputId="hogangnono"
              inputValue={isUpdate ? updateData.hogangnono : newData.hogangnono}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="호갱노노 링크를 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextInput
              inputTitle="까치정보"
              inputId="kkachi"
              inputValue={isUpdate ? updateData.kkachi : newData.kkachi}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="까치정보 링크를 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextInput
              inputTitle="교차로"
              inputId="gyokcharo"
              inputValue={isUpdate ? updateData.gyokcharo : newData.gyokcharo}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="교차로 링크를 입력해주세요."
            />
          </InputGroup>
        </InfoContainer>
      </InfoGroup>
      <Button
        title="저장하기"
        height={78}
        radius={20}
        handleClickBtn={isUpdate ? handleClickUpdateData : handleClickCreateData}
      />
    </Wrapper>
  );
}
