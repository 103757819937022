import { css } from "@emotion/react";
import { Default } from "./emotion";

export const GlobalStyle = css`
  @import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&family=Noto+Sans+KR:wght@100..900&display=swap");
  @font-face {
    font-family: "Noto Sans KR", sans-serif;
    src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_one@1.0/Chosunilbo_myungjo.woff")
      format("woff");
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    ::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
  }

  body {
    font-family: ${Default.font};
    color: ${Default.color};
    background: ${Default.bg};
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
  }

  em,
  i {
    font-style: normal;
  }

  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  button {
    border: 0;
    cursor: pointer;
    background: #fff;
  }

  button,
  input,
  textarea {
    border: ${Default.border};
  }

  button:focus,
  input:focus,
  textarea:focus {
    outline: none;
  }

  button {
    transition: opacity 0.4s ease;
  }
  button:hover {
    opacity: 0.8;
  }

  .container {
    width: 100%;
    min-width: 1624px;
    margin: 0 auto;
  }
`;
