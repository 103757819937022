import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { collection, getDocs, query, doc, orderBy, updateDoc } from 'firebase/firestore';

import { VillageNewType } from '@pages/village/new/type';
import { db } from '@commons/libraries/firebase/firebase.config';
import { Wrapper } from '@pages/item/list/style';
import Table from '@components/table';

export default function ItemList() {
  const { typeId } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState<VillageNewType[]>([]);
  const tableHead = ['아파트명', '단지정보'];
  const tableWidth = ['20%', '80%'];

  useEffect(() => {
    const fetchData = async () => {
      if (typeId) {
        const result: any = await getVillageData(typeId);
        setData(result);
      }
    };

    void fetchData();
  }, [typeId]);

  const getVillageData = async (typeId: string) => {
    const dataQuery = query(collection(db, String(typeId)), orderBy('apartmentName', 'asc'));
    const getData = await getDocs(dataQuery);
    const result = getData.docs.map((doc) => {
      const docData = doc.data();
      return {
        id: doc.id,
        apartmentName: docData.apartmentName,
        itemList: docData.itemList,
      };
    });
    return result || [];
  };

  const handleClickUpdate = (id: string, itemId: string) => async () => {
    navigate(`/item/update/${typeId}/${id}/${itemId}`);
  };

  const handleClickDelete = (id: string, itemId: string) => async () => {
    let updatedData = null;

    data.forEach((list) => {
      if (list.id === id) {
        list.itemList = list.itemList.filter((item) => item.id !== itemId);
        updatedData = { itemList: list.itemList };
      }
    });

    if (updatedData) {
      try {
        const userRef = doc(db, String(typeId), String(id));
        await updateDoc(userRef, updatedData);
        alert('매물이 수정되었습니다.');
        window.location.reload();
      } catch (error) {
        console.error('ErrorMessage:', error);
      }
    }
  };

  return (
    <Wrapper>
      <Table
        type="item"
        data={data}
        tableHead={tableHead}
        tableWidth={tableWidth}
        handleClickDelete={handleClickDelete}
        handleClickUpdate={handleClickUpdate}
        router={String(typeId)}
      />
    </Wrapper>
  );
}
