import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
  collection,
  getDocs,
  orderBy,
  query,
  doc,
  updateDoc,
  arrayUnion,
  getDoc,
} from 'firebase/firestore';

import { CustomChangeEvent, CustomTextareaChangeEvent } from '@commons/types/global.types';
import { ItemNewType } from '@pages/item/new/type';
import { menuListState } from '@store/store';
import { InfoContainer, InfoGroup, InputGroup, Wrapper } from '@pages/item/new/style';
import { db } from '@commons/libraries/firebase/firebase.config';
import TextInput from '@components/newItem/textInput';
import TextArea from '@components/newItem/textarea';
import SelectBox from '@components/newItem/selectBox';
import Button from '@components/button';
import PdfInput from '@components/newItem/pdfInput';
import ImageInput from '@components/newItem/imageInput';

export default function ItemNew() {
  const { typeId, itemId, id } = useParams();
  const navigate = useNavigate();
  const menuList = useRecoilValue(menuListState);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [imageURL, setImageURL] = useState<string[]>([]);
  const [fileURL, setFileURL] = useState<string[]>([]);
  const [selectList, setSelectList] = useState<any>([]);
  const [selectList_apartment, setSelectList_apartment] = useState<any>([]);
  const [newData, setNewData] = useState<ItemNewType>({
    id: uuidv4(),
    typeId: '',
    typeName: '',
    apartmentId: '',
    apartmentName: '',
    ownerName: '',
    ownerPhoneAgency: { id: '', title: '' },
    ownerPhone: '',
    ownerMemo: '',
    managerName: '',
    managerPhoneAgency: { id: '', title: '' },
    managerPhone: '',
    manageMemo: '',
    advertisedPrice: '',
    desiredPrice: '',
    address: '',
    location: '',
    apartmentDong: '',
    apartmentHo: '',
    householdNum: '',
    memo: '',
    mortgage: '',
    interior: '',
    term: '',
    moveIn: '',
    tenantStatus: '',
    visitSchedule: '',
    introductionTime: '',
    password: '',
    notes: '',
    roomNum: '',
    bathroomNum: '',
    area1: '',
    area2: '',
    useApproval: '',
    fullFloor: '',
    underground: '',
    parking: '',
    pdfFiles: [{ name: '', url: '' }],
    itemImageUrls: [],
  });
  const [updateData, setUpdateData] = useState<any>({
    id: '',
    typeId: '',
    typeName: '',
    apartmentId: '',
    apartmentName: '',
    ownerName: '',
    ownerPhoneAgency: '',
    ownerPhone: '',
    ownerMemo: '',
    managerName: '',
    managerPhoneAgency: '',
    managerPhone: '',
    manageMemo: '',
    advertisedPrice: '',
    desiredPrice: '',
    address: '',
    location: '',
    apartmentDong: '',
    apartmentHo: '',
    householdNum: '',
    memo: '',
    mortgage: '',
    interior: '',
    term: '',
    moveIn: '',
    tenantStatus: '',
    visitSchedule: '',
    introductionTime: '',
    password: '',
    notes: '',
    roomNum: '',
    bathroomNum: '',
    area1: '',
    area2: '',
    useApproval: '',
    fullFloor: '',
    underground: '',
    parking: '',
    pdfFiles: [{ name: '', url: '' }],
    itemImageUrls: [],
  });
  const selectList_phoneAgency = [
    {
      id: 'KT',
      title: 'KT',
    },
    {
      id: 'SKT',
      title: 'SKT',
    },
    {
      id: 'LGU+',
      title: 'LGU+',
    },
    {
      id: '알뜰 KT',
      title: '알뜰 KT',
    },
    {
      id: '알뜰 SKT',
      title: '알뜰 SKT',
    },
    {
      id: '알뜰 LGU+',
      title: '알뜰 LGU+',
    },
  ];

  useEffect(() => {
    if (typeId && itemId && id) {
      setIsUpdate(true);
      void getUpdateData(String(typeId), String(itemId), String(id));
    } else {
    }
  }, [typeId, itemId, id]);

  const getUpdateData = async (typeId: string, itemId: string, id: string) => {
    try {
      const dataQuery = query(collection(db, String(typeId)));
      const getData = await getDocs(dataQuery);
      const result: any = getData.docs
        .filter((doc) => doc.id === itemId)
        .map((doc) => {
          const docData = doc.data();
          return {
            ...docData,
            id: doc.id,
          } as any;
        });

      if (result.length > 0) {
        const itemList = result[0].itemList;
        if (Array.isArray(itemList)) {
          const foundItem = itemList.find((item: any) => item.id === id);
          setUpdateData(foundItem);

          if (foundItem.itemImageUrls.length > 0) {
            setImageURL(foundItem.itemImageUrls);
          }
          if (foundItem.pdfFiles.length > 0) {
            setFileURL(foundItem.pdfFiles);
          }
          return foundItem;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      console.error('ErrorMessage:', error);
    }
  };

  useEffect(() => {
    const villageMenu = menuList.find((menu) => menu.id === 'village');
    if (villageMenu) {
      setSelectList([...villageMenu.subMenu]);
    }
  }, [menuList]);

  useEffect(() => {
    if (isUpdate) {
      setUpdateData((prevData: any) => ({
        ...prevData,
        pdfFiles: fileURL,
        itemImageUrls: imageURL,
      }));
    } else {
      setNewData((prevData) => ({
        ...prevData,
        pdfFiles: fileURL,
        itemImageUrls: imageURL,
      }));
    }
  }, [imageURL, fileURL, isUpdate]);

  useEffect(() => {
    if (isUpdate) {
      const getVillageData = async () => {
        try {
          const dataQuery = query(collection(db, String(typeId)), orderBy('apartmentName', 'asc'));
          const getData = await getDocs(dataQuery);
          const result: any = getData.docs.map((doc) => {
            const docData = doc.data();
            return {
              id: doc.id,
              title: docData.apartmentName,
            };
          });
          void setSelectList_apartment(result);
        } catch (error) {
          console.error('ErrorMessage:', error);
        }
      };

      void getVillageData();
    }
  }, [isUpdate, typeId]);

  useEffect(() => {
    if (newData.typeId) {
      const getVillageData = async () => {
        const dataQuery = query(collection(db, newData.typeId), orderBy('apartmentName', 'asc'));
        const getData = await getDocs(dataQuery);
        const result: any = getData.docs.map((doc) => {
          const docData = doc.data();
          return {
            id: doc.id,
            title: docData.apartmentName,
          };
        });

        void setSelectList_apartment(result);
      };

      void getVillageData();
    }
  }, [newData.typeId]);

  const handleChangeInput = (e: CustomChangeEvent | CustomTextareaChangeEvent) => {
    if (isUpdate) {
      const { id, value } = e.target;
      setUpdateData((prevState: any) => ({
        ...prevState,
        [id]: value,
      }));
    } else {
      const { id, value } = e.target;
      setNewData((prevState) => ({
        ...prevState,
        [id]: value,
      }));
    }
  };

  const handleSelectValue = (selectValue: any, inputId: string) => {
    if (inputId === 'typeId') {
      setNewData((prevState) => ({
        ...prevState,
        typeId: selectValue.id,
        typeName: selectValue.id === 'new' ? '' : selectValue.title,
      }));
      if (isUpdate) {
        setUpdateData((prevState: any) => ({
          ...prevState,
          typeId: selectValue.id,
          typeName: selectValue.id === 'new' ? '' : selectValue.title,
        }));
      }
      return;
    }

    if (inputId === 'apartmentId') {
      setNewData((prevState) => ({
        ...prevState,
        apartmentId: selectValue.id,
        apartmentName: selectValue.id === 'new' ? '' : selectValue.title,
      }));
      if (isUpdate) {
        setUpdateData((prevState: any) => ({
          ...prevState,
          apartmentId: selectValue.id,
          apartmentName: selectValue.id === 'new' ? '' : selectValue.title,
        }));
      }
      return;
    }

    if (inputId === 'ownerPhoneAgency' || inputId === 'managerPhoneAgency') {
      setNewData((prevState) => ({
        ...prevState,
        [inputId]: selectValue,
      }));
      if (isUpdate) {
        setUpdateData((prevState: any) => ({
          ...prevState,
          [inputId]: selectValue,
        }));
      }
      return;
    }
  };

  const handleClickCreateData = async () => {
    try {
      const userRef = doc(db, newData.typeId, newData.apartmentId);
      await updateDoc(userRef, {
        itemList: arrayUnion(newData),
      });
      alert('매물이 등록되었습니다.');
      void navigate('/');
    } catch (error) {
      console.error('ErrorMessage:', error);
    }
  };

  const handleClickUpdateData = async () => {
    try {
      const userRef = doc(db, String(typeId), String(itemId));

      const docSnap = await getDoc(userRef);
      if (docSnap.exists()) {
        const docData = docSnap.data();
        const itemList = docData.itemList || [];

        const updatedItemList = itemList.map((item: any) => {
          if (item.id === id) {
            return {
              ...item,
              ...updateData,
            };
          }
          return item;
        });

        await updateDoc(userRef, {
          itemList: updatedItemList,
        });

        alert('매물이 수정되었습니다.');
        void navigate('/');
      }
    } catch (error) {
      console.error('ErrorMessage:', error);
    }
  };

  return (
    <Wrapper>
      <InfoGroup>
        <div className="group-title">기본 정보</div>
        <InfoContainer>
          <InputGroup>
            <SelectBox
              isValue={isUpdate ? updateData.typeName : newData.typeName}
              inputId="typeId"
              list={selectList}
              inputTitle="단지 선택"
              selectBoxPlaceholder="동을 선택해주세요."
              handleSelectValue={handleSelectValue}
            />
          </InputGroup>
          <InputGroup>
            <SelectBox
              isValue={isUpdate ? updateData.apartmentName : newData.apartmentName}
              inputId="apartmentId"
              list={selectList_apartment}
              inputTitle="아파트 선택"
              selectBoxPlaceholder="아파트를 선택해주세요."
              handleSelectValue={handleSelectValue}
            />
          </InputGroup>
          <InputGroup>
            <TextInput
              inputTitle="소유자 성명"
              inputId="ownerName"
              inputValue={isUpdate ? updateData.ownerName : newData.ownerName}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="성명을 입력해주세요."
            />
          </InputGroup>
          <InputGroup className="flex-input-group">
            <SelectBox
              isValue={
                isUpdate ? updateData.ownerPhoneAgency.title : newData.ownerPhoneAgency.title
              }
              inputId="ownerPhoneAgency"
              list={selectList_phoneAgency}
              inputTitle="소유자 통신사"
              selectBoxPlaceholder="통신사를 선택해주세요."
              handleSelectValue={handleSelectValue}
            />
            <TextInput
              inputTitle="소유자 연락처"
              inputId="ownerPhone"
              inputValue={isUpdate ? updateData.ownerPhone : newData.ownerPhone}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="연락처를 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextInput
              inputTitle="소유자 메모"
              inputId="ownerMemo"
              inputValue={isUpdate ? updateData.ownerMemo : newData.ownerMemo}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="소유자에 대한 메모를 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextInput
              inputTitle="담당자 성명"
              inputId="managerName"
              inputValue={isUpdate ? updateData.managerName : newData.managerName}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="성명을 입력해주세요."
            />
          </InputGroup>
          <InputGroup className="flex-input-group">
            <SelectBox
              isValue={
                isUpdate ? updateData.managerPhoneAgency.title : newData.managerPhoneAgency.title
              }
              inputId="managerPhoneAgency"
              list={selectList_phoneAgency}
              inputTitle="담당자 통신사"
              selectBoxPlaceholder="통신사를 선택해주세요."
              handleSelectValue={handleSelectValue}
            />
            <TextInput
              inputTitle="담당자 연락처"
              inputId="managerPhone"
              inputValue={isUpdate ? updateData.managerPhone : newData.managerPhone}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="연락처를 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextInput
              inputTitle="담당자 메모"
              inputId="manageMemo"
              inputValue={isUpdate ? updateData.manageMemo : newData.manageMemo}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="담당자에 대한 메모를 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <PdfInput
              isUpdate={isUpdate}
              fileId="pdfFiles"
              inputTitle="PDF 파일 첨부"
              fileURL={fileURL}
              setFileURL={setFileURL}
            />
          </InputGroup>
          <InputGroup className="flex-input-group">
            <TextInput
              inputTitle="광고가"
              inputId="advertisedPrice"
              inputValue={isUpdate ? updateData.advertisedPrice : newData.advertisedPrice}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="광고가를 입력해주세요."
            />
            <TextInput
              inputTitle="희망가"
              inputId="desiredPrice"
              inputValue={isUpdate ? updateData.desiredPrice : newData.desiredPrice}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="희망가를 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextInput
              inputTitle="주소"
              inputId="address"
              inputValue={isUpdate ? updateData.address : newData.address}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="주소를 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextInput
              inputTitle="위치"
              inputId="location"
              inputValue={isUpdate ? updateData.location : newData.location}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="위치를 입력해주세요."
            />
          </InputGroup>
          <InputGroup className="flex-input-group">
            <TextInput
              inputTitle="아파트(동)"
              inputId="apartmentDong"
              inputValue={isUpdate ? updateData.apartmentDong : newData.apartmentDong}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="동을 입력해주세요."
            />
            <TextInput
              inputTitle="아파트(호수)"
              inputId="apartmentHo"
              inputValue={isUpdate ? updateData.apartmentHo : newData.apartmentHo}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="호수를 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextInput
              inputTitle="세대수"
              inputId="householdNum"
              inputValue={isUpdate ? updateData.householdNum : newData.householdNum}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="세대수를 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextArea
              inputTitle="근저당"
              inputId="mortgage"
              inputHeight={250}
              inputValue={isUpdate ? updateData.mortgage : newData.mortgage}
              handleChangeTextarea={handleChangeInput}
              inputPlaceholder="근저당을 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextArea
              inputTitle="인테리어 상태"
              inputId="interior"
              inputHeight={250}
              inputValue={isUpdate ? updateData.interior : newData.interior}
              handleChangeTextarea={handleChangeInput}
              inputPlaceholder="인테리어 상태를 자세히 입력해주세요."
            />
          </InputGroup>
          <InputGroup className="flex-input-group">
            <TextInput
              inputTitle="계약기간"
              inputId="term"
              inputValue={isUpdate ? updateData.term : newData.term}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="계약기간을 입력해주세요."
            />
            <TextInput
              inputTitle="입주시기"
              inputId="moveIn"
              inputValue={isUpdate ? updateData.moveIn : newData.moveIn}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="입주시기를 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextInput
              inputTitle="세입자 현황"
              inputId="tenantStatus"
              inputValue={isUpdate ? updateData.tenantStatus : newData.tenantStatus}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="세입자 현황을 입력해주세요."
            />
          </InputGroup>
          <InputGroup className="flex-input-group">
            <TextInput
              inputTitle="방문일정"
              inputId="visitSchedule"
              inputValue={isUpdate ? updateData.visitSchedule : newData.visitSchedule}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="방문기간을 입력해주세요."
            />
            <TextInput
              inputTitle="소개시간"
              inputId="introductionTime"
              inputValue={isUpdate ? updateData.introductionTime : newData.introductionTime}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="소개시간을 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextInput
              inputTitle="비밀번호/열쇠"
              inputId="password"
              inputValue={isUpdate ? updateData.password : newData.password}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="비밀번호/열쇠유무를 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextArea
              inputTitle="대상물 특징/기타 참고사항"
              inputId="notes"
              inputHeight={250}
              inputValue={isUpdate ? updateData.notes : newData.notes}
              handleChangeTextarea={handleChangeInput}
              inputPlaceholder="대상물 특징/기타 참고사항을 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <ImageInput
              isUpdate={isUpdate}
              fileId="itemImageUrls"
              isMultiple
              inputTitle="매물 관련 이미지"
              imageURL={imageURL}
              setImageURL={setImageURL}
            />
          </InputGroup>
        </InfoContainer>
      </InfoGroup>
      <InfoGroup>
        <div className="group-title">면적정보(평형)</div>
        <InfoContainer>
          <InputGroup className="flex-input-group">
            <TextInput
              inputTitle="방 개수"
              inputId="roomNum"
              inputValue={isUpdate ? updateData.roomNum : newData.roomNum}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="방 개수를 입력해주세요."
            />
            <TextInput
              inputTitle="욕실 개수"
              inputId="bathroomNum"
              inputValue={isUpdate ? updateData.bathroomNum : newData.bathroomNum}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="욕실 개수를 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextInput
              inputTitle="계약면적/사용면젹"
              inputId="area1"
              inputValue={isUpdate ? updateData.area1 : newData.area1}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="계약면적과 사용면적을 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextInput
              inputTitle="공급면적/전용면적"
              inputId="area2"
              inputValue={isUpdate ? updateData.area2 : newData.area2}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="공급면적과 전용면적을 입력해주세요."
            />
          </InputGroup>
        </InfoContainer>
      </InfoGroup>
      <InfoGroup>
        <div className="group-title">부가정보</div>
        <InfoContainer>
          <InputGroup>
            <TextInput
              inputTitle="사용승인일"
              inputId="useApproval"
              inputValue={isUpdate ? updateData.useApproval : newData.useApproval}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="사용승인일을 입력해주세요."
            />
          </InputGroup>
          <InputGroup className="flex-input-group">
            <TextInput
              inputTitle="전체 층"
              inputId="fullFloor"
              inputValue={isUpdate ? updateData.fullFloor : newData.fullFloor}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="전체 층을 입력해주세요."
            />
            <TextInput
              inputTitle="지하"
              inputId="underground"
              inputValue={isUpdate ? updateData.underground : newData.underground}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="지하 층을 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextInput
              inputTitle="주차시설"
              inputId="parking"
              inputValue={isUpdate ? updateData.parking : newData.parking}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="주차시설을 입력해주세요."
            />
          </InputGroup>
        </InfoContainer>
      </InfoGroup>
      <Button
        title="저장하기"
        height={78}
        radius={20}
        handleClickBtn={isUpdate ? handleClickUpdateData : handleClickCreateData}
      />
    </Wrapper>
  );
}
