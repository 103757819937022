import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { storage } from '@commons/libraries/firebase/firebase.config';

import {
  DefaultImageInput,
  InputTitle,
  PdfFileBox,
  Wrapper,
} from '@components/newItem/pdfInput/style';

import { ImageInputType } from '@components/newItem/pdfInput/type';
import { CustomChangeEvent } from '@commons/types/global.types';

export default function PdfInput({
  inputTitle,
  fileId,
  fileURL,
  setFileURL,
  isUpdate,
}: ImageInputType) {
  const handleChangeFile = async (e: CustomChangeEvent) => {
    const files = e.target.files;
    if (!files) return;

    const promises = Array.from(files).map(async (file) => {
      const storageRef = ref(storage, `pdfs/${file.name}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      return { name: file.name, url: downloadURL };
    });

    const uploadedFiles = await Promise.all(promises);

    setFileURL((prev: any) => [...prev, ...uploadedFiles]);
  };

  const handleRemoveFile = (idx: number) => async () => {
    const newFileURLs = [...fileURL];
    newFileURLs.splice(idx, 1);
    setFileURL(newFileURLs);

    if (!isUpdate) {
      const storageRef = ref(storage, `pdfs/${newFileURLs[idx].name}`);
      await deleteObject(storageRef);
    }
  };

  return (
    <Wrapper>
      {inputTitle && <InputTitle className="input-title">{inputTitle}</InputTitle>}
      <DefaultImageInput>
        <label className="file-custom" htmlFor={fileId}>
          <span className="file-icon">
            <img src={`${process.env.PUBLIC_URL}/assets/images/icon/pdf.svg`} alt="file" />
          </span>
          <p>파일을 첨부해주세요.</p>
        </label>
        <input type="file" id={fileId} accept=".pdf" onChange={handleChangeFile} multiple />
      </DefaultImageInput>
      <PdfFileBox>
        {fileURL.map((pdf: any, idx: number) => (
          <div className="file-box" key={idx}>
            <span className="file-icon">
              <img src={`${process.env.PUBLIC_URL}/assets/images/icon/pdf-g.svg`} alt="file" />
            </span>
            <p>{fileURL[idx].name}</p>
            <span className="close-icon" onClick={handleRemoveFile(idx)}>
              <img src={`${process.env.PUBLIC_URL}/assets/images/icon/close.svg`} alt="close" />
            </span>
          </div>
        ))}
      </PdfFileBox>
    </Wrapper>
  );
}
