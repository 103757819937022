import styled from "@emotion/styled";
import { mediaQuery } from "@commons/styles/mediaQuery";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const InfoGroup = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  background: #fff;
  padding: 20px 30px;
  border-radius: 20px;

  .group-title {
    position: absolute;
    top: 20px;
    left: 30px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.005em;
  }

  ${mediaQuery[0]} {
    justify-content: space-between;
    .group-title {
      position: static;
    }
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
  width: 480px;

  ${mediaQuery[0]} {
    width: 80%;
  }
`;
export const InputGroup = styled.div``;
