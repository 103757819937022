import React, { forwardRef } from 'react';
import { Wrapper } from '@components/input/style';
import { IInputType } from '@components/input/type';

const InputItem = forwardRef<HTMLInputElement, IInputType>(
  (
    {
      inputType,
      inputId,
      inputWidth,
      inputHeight,
      inputValue,
      inputPlaceholder,
      handleChangeInput,
      inputBg,
      inputBorder,
      isReadOnly,
    },
    inputRef
  ) => {
    return (
      <Wrapper
        inputWidth={inputWidth}
        inputHeight={inputHeight}
        inputBg={inputBg}
        inputBorder={inputBorder}
      >
        <input
          readOnly={isReadOnly}
          type={inputType ? inputType : 'text'}
          id={inputId}
          value={inputValue}
          placeholder={inputPlaceholder}
          onChange={handleChangeInput}
          ref={inputRef}
        />
      </Wrapper>
    );
  }
);

export default InputItem;
