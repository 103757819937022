import { useNavigate } from 'react-router';
import { useEffect, useRef, useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';

import { UserLoginType } from '@pages/login/type';
import { CustomChangeEvent } from '@commons/types/global.types';
import { authService } from '@commons/libraries/firebase/firebase.config';
import { LoginWrapper, Logo, Title, Wrapper } from '@pages/login/style';
import InputItem from '@components/input';
import Button from '@components/button';

export default function Login() {
  const navigate = useNavigate();
  const passwordRef = useRef<HTMLInputElement | null>(null);
  const [isShowPwChecked, setShowPwChecked] = useState<boolean>(false);
  const [userLogin, setUserLogin] = useState<UserLoginType>({
    email: '',
    password: '',
  });

  const handleChangeInput = (e: CustomChangeEvent) => {
    const { id, value } = e.target;
    setUserLogin((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleLogin = async () => {
    const { email, password } = userLogin;

    if (email === '') {
      return alert('아이디를 입력해주세요.');
    }
    if (password === '') {
      return alert('비밀번호를 입력해주세요.');
    }

    try {
      await signInWithEmailAndPassword(authService, email, password);
      void navigate('/');
    } catch (error) {
      alert('아이디, 비밀번호를 확인해주세요.');
    }
  };

  useEffect(() => {
    if (passwordRef.current) {
      passwordRef.current.type = isShowPwChecked ? 'text' : 'password';
    }
  }, [isShowPwChecked]);

  return (
    <Wrapper>
      <Logo onClick={() => navigate('/')}>
        <img src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`} alt="rosy" />
      </Logo>
      <Title>LOGIN</Title>
      <LoginWrapper>
        <div className="email-input-wrapper">
          <InputItem
            inputId="email"
            inputValue={userLogin.email}
            inputPlaceholder="아이디를 입력해주세요."
            handleChangeInput={handleChangeInput}
            inputBorder
          />
        </div>
        <div className="password-input-wrapper">
          <InputItem
            inputType="password"
            inputId="password"
            inputValue={userLogin.password}
            inputPlaceholder="비밀번호를 입력해주세요."
            handleChangeInput={handleChangeInput}
            inputRef={passwordRef}
            inputBorder
          />
          <span
            className="password-icon"
            onClick={() => {
              setShowPwChecked((prev) => !prev);
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/icon/${
                isShowPwChecked ? 'eye-open' : 'eye-closed'
              }.svg`}
              alt="rosy"
            />
          </span>
        </div>
        <Button title="LOGIN" handleClickBtn={handleLogin} />
      </LoginWrapper>
    </Wrapper>
  );
}
