import styled from '@emotion/styled';
import { Common, Default } from '@commons/styles/emotion';
import { IPropsTextAreaType } from '@components/newItem/textarea/type';

export const Wrapper = styled.div`
  textarea {
    resize: none;
    width: 100%;
    border-radius: 10px;
    border: ${Default.border};
    padding: 14px 28px;
    font-family: ${Default.font};
    font-weight: 400;
    font-style: normal;
    height: ${(props: IPropsTextAreaType) =>
      props.inputHeight ? `${props.inputHeight}px` : '122px'};
    &::placeholder {
      color: ${Common.color.lightGray};
    }
  }
`;

export const InputTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.005em;
  margin-bottom: 10px;
`;
