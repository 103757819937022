import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import { collection, getDocs, query } from 'firebase/firestore';

import { CustomChangeEvent, CustomTextareaChangeEvent } from '@commons/types/global.types';
import {
  InfoContainer,
  InfoGroup,
  InputGroup,
  InputTitle,
  Wrapper,
} from '@pages/item/detail/style';
import { PdfFileBox } from '@components/newItem/pdfInput/style';
import { PreviewImage, PreviewImageBox } from '@components/newItem/imageInput/style';
import { db } from '@commons/libraries/firebase/firebase.config';
import TextInput from '@components/newItem/textInput';
import TextArea from '@components/newItem/textarea';
import Button from '@components/button';

export default function ItemDetail() {
  const { typeId, itemId, id } = useParams();
  const navigate = useNavigate();
  const [imageURL, setImageURL] = useState<string[]>([]);
  const [fileURL, setFileURL] = useState<string[]>([]);
  const [data, setData] = useState<any>({});

  useEffect(() => {
    void getFetchData(String(typeId), String(itemId), String(id));
  }, [typeId, itemId, id]);

  const getFetchData = async (typeId: string, itemId: string, id: string) => {
    try {
      const dataQuery = query(collection(db, String(typeId)));
      const getData = await getDocs(dataQuery);
      const result: any = getData.docs
        .filter((doc) => doc.id === itemId)
        .map((doc) => {
          const docData = doc.data();
          return {
            ...docData,
            id: doc.id,
          } as any;
        });

      if (result.length > 0) {
        const itemList = result[0].itemList;
        if (Array.isArray(itemList)) {
          const foundItem = itemList.find((item: any) => item.id === id);
          setData(foundItem);

          if (foundItem.itemImageUrls.length > 0) {
            setImageURL(foundItem.itemImageUrls);
          }
          if (foundItem.pdfFiles.length > 0) {
            setFileURL(foundItem.pdfFiles);
          }
          return foundItem;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      console.error('ErrorMessage:', error);
    }
  };

  const handleChangeInput = (e: CustomChangeEvent | CustomTextareaChangeEvent) => {
    const { id, value } = e.target;
    setData((prevState: any) => ({
      ...prevState,
      [id]: value,
    }));
  };

  return (
    <Wrapper>
      <InfoGroup>
        <div className="group-title">기본 정보</div>
        <InfoContainer>
          <InputGroup>
            <TextInput
              isReadOnly
              inputTitle="동을 선택해주세요."
              inputId="typeId"
              inputValue={data.typeName}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="동을 선택해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextInput
              isReadOnly
              inputTitle="아파트 선택"
              inputId="apartmentId"
              inputValue={data.apartmentName}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="아파트를 선택해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextInput
              isReadOnly
              inputTitle="소유자 성명"
              inputId="ownerName"
              inputValue={data.ownerName}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="성명을 입력해주세요."
            />
          </InputGroup>
          <InputGroup className="flex-input-group">
            <TextInput
              isReadOnly
              inputTitle="소유자 통신사"
              inputId="ownerPhoneAgency"
              inputValue={data.ownerPhoneAgency?.title}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="통신사를 선택해주세요."
            />
            <TextInput
              isReadOnly
              inputTitle="소유자 연락처"
              inputId="ownerPhone"
              inputValue={data.ownerPhone}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="연락처를 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextInput
              isReadOnly
              inputTitle="소유자 메모"
              inputId="ownerMemo"
              inputValue={data.ownerMemo}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="소유자에 대한 메모를 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextInput
              isReadOnly
              inputTitle="담당자 성명"
              inputId="managerName"
              inputValue={data.managerName}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="성명을 입력해주세요."
            />
          </InputGroup>
          <InputGroup className="flex-input-group">
            <TextInput
              isReadOnly
              inputTitle="담당자 통신사"
              inputId="managerPhoneAgency"
              inputValue={data.managerPhoneAgency?.title}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="통신사를 선택해주세요."
            />
            <TextInput
              isReadOnly
              inputTitle="담당자 연락처"
              inputId="managerPhone"
              inputValue={data.managerPhone}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="연락처를 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextInput
              isReadOnly
              inputTitle="담당자 메모"
              inputId="manageMemo"
              inputValue={data.manageMemo}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="담당자에 대한 메모를 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <PdfFileBox>
              <InputTitle>PDF 파일 첨부</InputTitle>
              {fileURL.map((pdf: any, idx: number) => (
                <div
                  className="file-box file-box-click"
                  key={idx}
                  onClick={() => {
                    window.open(pdf.url, '_blank');
                  }}
                >
                  <span className="file-icon">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/icon/pdf-g.svg`}
                      alt="file"
                    />
                  </span>
                  <p>{pdf.name}</p>
                </div>
              ))}
            </PdfFileBox>
          </InputGroup>
          <InputGroup className="flex-input-group">
            <TextInput
              isReadOnly
              inputTitle="광고가"
              inputId="advertisedPrice"
              inputValue={data.advertisedPrice}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="광고가를 입력해주세요."
            />
            <TextInput
              isReadOnly
              inputTitle="희망가"
              inputId="desiredPrice"
              inputValue={data.desiredPrice}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="희망가를 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextInput
              isReadOnly
              inputTitle="주소"
              inputId="address"
              inputValue={data.address}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="주소를 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextInput
              isReadOnly
              inputTitle="위치"
              inputId="location"
              inputValue={data.location}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="위치를 입력해주세요."
            />
          </InputGroup>
          <InputGroup className="flex-input-group">
            <TextInput
              isReadOnly
              inputTitle="아파트(동)"
              inputId="apartmentDong"
              inputValue={data.apartmentDong}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="동을 입력해주세요."
            />
            <TextInput
              isReadOnly
              inputTitle="아파트(호수)"
              inputId="apartmentHo"
              inputValue={data.apartmentHo}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="호수를 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextInput
              isReadOnly
              inputTitle="세대수"
              inputId="householdNum"
              inputValue={data.householdNum}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="세대수를 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextArea
              isReadOnly
              inputTitle="근저당"
              inputId="mortgage"
              inputHeight={250}
              inputValue={data.mortgage}
              handleChangeTextarea={handleChangeInput}
              inputPlaceholder="근저당을 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextArea
              isReadOnly
              inputTitle="인테리어 상태"
              inputId="interior"
              inputHeight={250}
              inputValue={data.interior}
              handleChangeTextarea={handleChangeInput}
              inputPlaceholder="인테리어 상태를 자세히 입력해주세요."
            />
          </InputGroup>
          <InputGroup className="flex-input-group">
            <TextInput
              isReadOnly
              inputTitle="계약기간"
              inputId="term"
              inputValue={data.term}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="계약기간을 입력해주세요."
            />
            <TextInput
              isReadOnly
              inputTitle="입주시기"
              inputId="moveIn"
              inputValue={data.moveIn}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="입주시기를 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextInput
              isReadOnly
              inputTitle="세입자 현황"
              inputId="tenantStatus"
              inputValue={data.tenantStatus}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="세입자 현황을 입력해주세요."
            />
          </InputGroup>
          <InputGroup className="flex-input-group">
            <TextInput
              isReadOnly
              inputTitle="방문일정"
              inputId="visitSchedule"
              inputValue={data.visitSchedule}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="방문기간을 입력해주세요."
            />
            <TextInput
              isReadOnly
              inputTitle="소개시간"
              inputId="introductionTime"
              inputValue={data.introductionTime}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="소개시간을 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextInput
              isReadOnly
              inputTitle="비밀번호/열쇠"
              inputId="password"
              inputValue={data.password}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="비밀번호/열쇠유무를 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextArea
              isReadOnly
              inputTitle="대상물 특징/기타 참고사항"
              inputId="notes"
              inputHeight={250}
              inputValue={data.notes}
              handleChangeTextarea={handleChangeInput}
              inputPlaceholder="대상물 특징/기타 참고사항을 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <InputTitle>매물 관련 이미지</InputTitle>
            <PreviewImageBox previewLength={imageURL.length}>
              <div className="inner-scroll inner-scroll-img">
                {imageURL.map((src, idx) => (
                  <PreviewImage key={idx}>
                    <img src={src} alt={`preview-${idx}`} />
                  </PreviewImage>
                ))}
              </div>
            </PreviewImageBox>
          </InputGroup>
        </InfoContainer>
      </InfoGroup>
      <InfoGroup>
        <div className="group-title">면적정보(평형)</div>
        <InfoContainer>
          <InputGroup className="flex-input-group">
            <TextInput
              isReadOnly
              inputTitle="방 개수"
              inputId="roomNum"
              inputValue={data.roomNum}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="방 개수를 입력해주세요."
            />
            <TextInput
              isReadOnly
              inputTitle="욕실 개수"
              inputId="bathroomNum"
              inputValue={data.bathroomNum}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="욕실 개수를 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextInput
              isReadOnly
              inputTitle="계약면적/사용면젹"
              inputId="area1"
              inputValue={data.area1}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="계약면적과 사용면적을 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextInput
              isReadOnly
              inputTitle="공급면적/전용면적"
              inputId="area2"
              inputValue={data.area2}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="공급면적과 전용면적을 입력해주세요."
            />
          </InputGroup>
        </InfoContainer>
      </InfoGroup>
      <InfoGroup>
        <div className="group-title">부가정보</div>
        <InfoContainer>
          <InputGroup>
            <TextInput
              isReadOnly
              inputTitle="사용승인일"
              inputId="useApproval"
              inputValue={data.useApproval}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="사용승인일을 입력해주세요."
            />
          </InputGroup>
          <InputGroup className="flex-input-group">
            <TextInput
              isReadOnly
              inputTitle="전체 층"
              inputId="fullFloor"
              inputValue={data.fullFloor}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="전체 층을 입력해주세요."
            />
            <TextInput
              isReadOnly
              inputTitle="지하"
              inputId="underground"
              inputValue={data.underground}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="지하 층을 입력해주세요."
            />
          </InputGroup>
          <InputGroup>
            <TextInput
              isReadOnly
              inputTitle="주차시설"
              inputId="parking"
              inputValue={data.parking}
              handleChangeInput={handleChangeInput}
              inputPlaceholder="주차시설을 입력해주세요."
            />
          </InputGroup>
        </InfoContainer>
      </InfoGroup>
      <Button
        title="뒤로가기"
        height={78}
        radius={20}
        handleClickBtn={() => {
          navigate(-1);
        }}
      />
    </Wrapper>
  );
}
