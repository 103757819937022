import { Fragment } from 'react';
import { useNavigate } from 'react-router';
import { useRecoilValue } from 'recoil';

import { TableType } from '@components/table/type';
import { UserDataType } from '@pages/user/type';
import { loginUserState } from '@store/store';
import {
  ApartmentInfo,
  ApartmentName,
  DetailRow,
  Row,
  TableBody,
  TableHead,
  Wrapper,
} from '@components/table/style';

export default function Table({
  type,
  router,
  data,
  tableHead,
  tableWidth,
  handleClickDelete,
  handleClickUpdate,
}: TableType) {
  const navigate = useNavigate();
  const loginUser = useRecoilValue(loginUserState);

  const handleMoveToLink =
    (link: string, isDetail?: boolean, isItem?: boolean, id?: string) => () => {
      if (isDetail) {
        navigate(`/village/detail/${router}/${link}`);
        return;
      }
      if (isItem) {
        navigate(`/item/detail/${router}/${link}/${id}`);
        return;
      }
      window.open(link, '_blank');
    };

  const addressParts = (address: string) => {
    const splitString = address.split(' (');
    const result: string = splitString
      .map((el: string, idx: number) => {
        if (idx === 0) {
          return el;
        } else {
          return `\n(${el}`;
        }
      })
      .join('');
    return result;
  };

  return (
    <Wrapper className={type === 'villageDetail' ? 'scroll' : ''}>
      <TableHead>
        {tableHead.map((title: string, idx: number) => (
          <div key={`title-${idx}`} style={{ width: `${tableWidth[idx]}` }}>
            <span>{title}</span>
          </div>
        ))}
      </TableHead>
      <TableBody>
        {type === 'user' ? (
          <>
            {data.map((user: UserDataType, idx: number) => (
              <Row key={`user-${idx}`}>
                <div style={{ width: `${tableWidth[0] || 'auto'}` }}>
                  <span>{idx + 1}</span>
                </div>
                {Object.entries(user)
                  .filter(([key]) => key !== 'id' && key !== 'uid')
                  .map(([key, val], i: number) => (
                    <Fragment key={`uid-${i}`}>
                      <div style={{ width: `${tableWidth[i + 1] || 'auto'}` }}>
                        <span>{val}</span>
                      </div>
                    </Fragment>
                  ))}
                {handleClickDelete ? (
                  <div
                    className="table-delete-btn"
                    style={{ width: `${tableWidth[tableWidth.length - 1]}` }}
                  >
                    <span onClick={handleClickDelete(String(user.id))}>삭제</span>
                  </div>
                ) : null}
              </Row>
            ))}
          </>
        ) : type === 'village' ? (
          data.map((village: any, idx: number) => (
            <Row key={`village-${idx}`}>
              <div className="apartment-name" style={{ width: `${tableWidth[0]}` }}>
                <p>{village.apartmentName}</p>
                <p>
                  등록매물
                  <span className={village.itemList.length === 0 ? '' : 'active'}>
                    <em>
                      {village.itemList.length < 10
                        ? ('0' + village.itemList.length).slice(-2)
                        : village.itemList.length}
                    </em>
                    건
                  </span>
                </p>
              </div>
              <div className="apartment-info" style={{ width: `${tableWidth[1]}` }}>
                <div className="ai-link">
                  <div className="ai-link-detail" onClick={handleMoveToLink(village.id, true)}>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/icon/d-detail.svg`}
                      alt="detail"
                    />
                    <span>상세 단지정보</span>
                  </div>
                  <div className="ai-link-naver" onClick={handleMoveToLink(village.naver)}>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/icon/d-naver.svg`}
                      alt="naver"
                    />
                    <span>네이버</span>
                  </div>
                  <div className="ai-link-daum" onClick={handleMoveToLink(village.daum)}>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/icon/d-daum.svg`}
                      alt="daum"
                    />
                    <span>다음</span>
                  </div>
                  <div
                    className="ai-link-hogangnono"
                    onClick={handleMoveToLink(village.hogangnono)}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/icon/d-hogangnono.svg`}
                      alt="hogangnono"
                    />
                    <span>호갱노노</span>
                  </div>
                  <div className="ai-link-kkachi" onClick={handleMoveToLink(village.kkachi)}>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/icon/d-kkachi.svg`}
                      alt="kkachi"
                    />
                    <span>까치정보</span>
                  </div>
                  <div className="ai-link-gyokcharo" onClick={handleMoveToLink(village.gyokcharo)}>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/icon/d-gyokcharo.svg`}
                      alt="gyokcharo"
                    />
                    <span>교차로</span>
                  </div>
                </div>
                <div className="ai-meno">
                  <img src={`${process.env.PUBLIC_URL}/assets/images/icon/d-memo.svg`} alt="memo" />
                  <span>{village.memo}</span>
                </div>
                {village.itemList.length !== 0 &&
                  village.itemList.map((el: any, index: string) => (
                    <div className="ai-data" key={`el-${index}`}>
                      <div>
                        <p>
                          {el.apartmentDong}동 {el.apartmentHo}호 | 희망가 {el.desiredPrice}만원 |
                          광고가 {el.advertisedPrice}만원 | {el.ownerName} | {el.ownerPhone}
                        </p>
                        <span onClick={handleMoveToLink(village.id, false, true, el.id)}>
                          자세히 보기
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
              {handleClickUpdate && handleClickDelete && loginUser?.level ? (
                <div className="table-btn-group" style={{ width: `${tableWidth[2]}` }}>
                  <div className="tb-write" onClick={handleClickUpdate(village.id)}>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/icon/d-write.svg`}
                      alt="write"
                    />
                    <span>수정</span>
                  </div>
                  <div className="tb-delete" onClick={handleClickDelete(village.id)}>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/icon/d-delete.svg`}
                      alt="delete"
                    />
                    <span>삭제</span>
                  </div>
                </div>
              ) : null}
            </Row>
          ))
        ) : type === 'item' ? (
          data.map((item: any, index: number) => (
            <Row key={`item-id-${index}`}>
              <div className="apartment-name" style={{ width: `${tableWidth[0]}` }}>
                <p>{item.apartmentName}</p>
                <p>
                  등록매물
                  <span className={item.itemList.length === 0 ? '' : 'active'}>
                    <em>
                      {item.itemList.length < 10
                        ? ('0' + item.itemList.length).slice(-2)
                        : item.itemList.length}
                    </em>
                    건
                  </span>
                </p>
              </div>
              <div className="item-info" style={{ width: `${tableWidth[1]}` }}>
                {item.itemList.length !== 0 &&
                  item.itemList.map((el: any, x: string) => (
                    <div className="item-data-group" key={`item-${x}-el`}>
                      <div className="item-data-item">
                        <p>
                          {el.apartmentDong}동 {el.apartmentHo}호 | 희망가 {el.desiredPrice}만원 |
                          광고가 {el.advertisedPrice}만원 | {el.ownerName} | {el.ownerPhone}
                        </p>
                        <span onClick={handleMoveToLink(item.id, false, true, el.id)}>
                          자세히 보기
                        </span>
                      </div>
                      {handleClickUpdate && handleClickDelete ? (
                        <div className="table-btn-group">
                          <div className="tb-write" onClick={handleClickUpdate(item.id, el.id)}>
                            <img
                              src={`${process.env.PUBLIC_URL}/assets/images/icon/d-write.svg`}
                              alt="write"
                            />
                            <span>수정</span>
                          </div>
                          {loginUser?.level && (
                            <div className="tb-delete" onClick={handleClickDelete(item.id, el.id)}>
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/images/icon/d-delete.svg`}
                                alt="delete"
                              />
                              <span>삭제</span>
                            </div>
                          )}
                        </div>
                      ) : null}
                    </div>
                  ))}
              </div>
            </Row>
          ))
        ) : type === 'villageDetail' && data ? (
          <DetailRow>
            <ApartmentName style={{ width: `${tableWidth[0]}` }}>
              {data.apartmentName}
            </ApartmentName>
            <ApartmentInfo style={{ width: `${tableWidth[1]}` }}>
              <div className="data-img">
                <div className="title">단지정보</div>
                <div className="cont">
                  <div className="img-wrapper">
                    <p>
                      <span>{data.apartmentName}</span>
                      <span>{addressParts(data.apartmentAddress)}</span>
                    </p>
                    <div className="img-box">
                      <img src={`${data.representativeImageUrl[0]}`} alt="단지 대표이미지" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="data-img-scroll">
                <div className="title">타입별</div>
                <div className="cont">
                  <div className="scroll-wrapper">
                    {data.typeImageUrls.map((img: string, idx: number) => (
                      <div className="img-box" key={idx}>
                        <img src={`${img}`} alt="단지 대표이미지" />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="data-text-info">
                <div className="data-text">
                  <div className="title">정보 링크</div>
                  <div className="cont">
                    <div className="ai-link">
                      <div className="ai-link-naver" onClick={handleMoveToLink(data.naver)}>
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/icon/d-naver.svg`}
                          alt="naver"
                        />
                        <span>네이버</span>
                      </div>
                      <div className="ai-link-daum" onClick={handleMoveToLink(data.daum)}>
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/icon/d-daum.svg`}
                          alt="daum"
                        />
                        <span>다음</span>
                      </div>
                      <div
                        className="ai-link-hogangnono"
                        onClick={handleMoveToLink(data.hogangnono)}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/icon/d-hogangnono.svg`}
                          alt="hogangnono"
                        />
                        <span>호갱노노</span>
                      </div>
                      <div className="ai-link-kkachi" onClick={handleMoveToLink(data.kkachi)}>
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/icon/d-kkachi.svg`}
                          alt="kkachi"
                        />
                        <span>까치정보</span>
                      </div>
                      <div className="ai-link-gyokcharo" onClick={handleMoveToLink(data.gyokcharo)}>
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/icon/d-gyokcharo.svg`}
                          alt="gyokcharo"
                        />
                        <span>교차로</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="data-text">
                  <div className="title">주소</div>
                  <div className="cont">{data.apartmentAddress}</div>
                </div>
                <div className="data-text">
                  <div className="title">관리사무소 전화번호</div>
                  <div className="cont">{data.apartmentPhone}</div>
                </div>
                <div className="data-text">
                  <div className="title">총 세대수</div>
                  <div className="cont">{data.allNumber}</div>
                </div>
                <div className="data-text">
                  <div className="title">최저층/최고층</div>
                  <div className="cont">{`${data.lowFloor} / ${data.highFloor}`}</div>
                </div>
                <div className="data-text">
                  <div className="title">주차대수</div>
                  <div className="cont">{data.parkingLot}</div>
                </div>
                <div className="data-text">
                  <div className="title">복도유형</div>
                  <div className="cont">{data.corridorType}</div>
                </div>
                <div className="data-text">
                  <div className="title">선수관리비</div>
                  <div className="cont">{data.maintenanceCost}</div>
                </div>
                <div className="data-text">
                  <div className="title">메모</div>
                  <div className="cont">{data.memo}</div>
                </div>
              </div>
            </ApartmentInfo>
          </DetailRow>
        ) : null}
      </TableBody>
    </Wrapper>
  );
}
